<template>
    <section :key="update" v-if="gvl !== null">
        <div class="d-flex">
            <div>
                <h2>{{ title }}</h2>
            </div>
            <div class="button-group ml-auto">
                <button :class="['btn', showAll ? 'btn-primary' : 'btn-outline-primary' ]" @click="showAll = true">
                    <span v-if="custom">All Custom Vendors </span>
                    <span v-else>All Vendors </span>
                    <span class="badge badge-dark">{{ Object.keys(vendors).length }}</span>
                </button>
                <button :class="[ 'btn', showAll ? 'btn-outline primary': 'btn-primary' ]" @click="showAll = false">
                    Selected Vendors
                    <span class="badge badge-dark">{{ selectedVendors.length }}</span>
                </button>
            </div>
        </div>
        <p>{{ subtitle }}</p>

        <b-alert v-if="error !== null" variant="danger" :show="true" dismissible>{{ error }}</b-alert>

        <b-form-checkbox-group name="selectedVendors" v-model="selectedVendors">
            <table class="table" v-if="Object.keys(filteredVendors).length > 0">
                <col width="30%">
                <col width="5%">
                <col width="13%">
                <col width="13%">
                <col width="13%">
                <col width="13%">
                <col width="13%">
                <thead>
                <tr>
                    <th>
                        <div class="d-flex justify-content-between">
                            <span>Vendor name</span>
                            <span>id</span>
                        </div>
                    </th>
                    <th>Restrictions</th>
                    <th>Purposes <i class="bx bxs-help-circle" v-b-modal.modal-purposes></i></th>
                    <th>Legitimate Interests <i class="bx bxs-help-circle" v-b-modal.modal-purposes></i></th>
                    <th>Special Purposes <i class="bx bxs-help-circle" v-b-modal.modal-special-purposes></i></th>
                    <th>Features <i class="bx bxs-help-circle" v-b-modal.modal-features></i></th>
                    <th>Special Features <i class="bx bxs-help-circle" v-b-modal.modal-special-features></i></th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="v in filteredVendors" :key="'v-' + v.id">
                        <td class="d-flex justify-content-between">
                            <span v-if="showAll">
                                <b-form-checkbox :value="v.id" :id="'v-' + v.id">{{ v.name }}</b-form-checkbox>
                            </span>
                            <span v-else>{{ v.name }}</span>
                            <span class="text-muted">
                                <img v-if="v.type === 'AdTech'" src="/images/google_adtech.svg" alt="Google AdTech" class="adtech">
                                {{ v.id }}
                            </span>
                        </td>
                        <td>
                            <a href="#" @click.prevent="currentVendor = v" :class="['btn', 'btn-sm', hasRestrictions(v) ? 'btn-danger' : 'btn-link']">Restrictions</a>
                        </td>
                        <td>{{ v.purposes.length > 0 ? v.purposes : '-' }}</td>
                        <td>{{ v.legIntPurposes.length > 0 ? v.legIntPurposes : '-'}}</td>
                        <td>{{ v.specialPurposes.length > 0 ? v.specialPurposes : '-'}}</td>
                        <td>{{ v.features.length > 0 ? v.features : '-' }}</td>
                        <td>{{ v.specialFeatures.length > 0 ? v.specialFeatures : '-' }}</td>
                    </tr>
                </tbody>
            </table>
            <div v-else class="alert alert-danger">
                <i class="bx bx-error"></i>
                No vendors have been selected yet.
                <button class="btn btn-link" @click="showAll = true">Select vendors</button>
            </div>
        </b-form-checkbox-group>

        <consent-publisher-restrictions v-if="currentVendor !== null" :vendor="currentVendor" :gvl="gvl" :custom-purposes="customPurposes"></consent-publisher-restrictions>

        <b-modal id="modal-purposes" title="List of Purposes / Legitimate Interests" ok-only ok-title="Close">
            <ol>
                <li v-for="p in gvl.purposes" :key="'p-' + p.id">{{ p.name }}</li>
            </ol>

            <h3 v-if="custom">Custom Purposes</h3>
            <ol v-if="custom" :start="Object.keys(customPurposes)[0].id">
                <li v-for="p in customPurposes" :key="'p-' + p.id">{{ p.name }}</li>
            </ol>
            <div class="alert alert-warning small">Purpose 1 can never be a legitimate interest.</div>
            <p class="small">For more information, consult <a href="https://iabeurope.eu/iab-europe-transparency-consent-framework-policies" target="_blank">iabeurope.eu</a>.</p>
        </b-modal>
        <b-modal id="modal-special-purposes" title="List of Special Purposes" ok-only ok-title="Close">
            <ol>
                <li v-for="sp in gvl.specialPurposes" :key="'sp-' + sp.id">{{ sp.name }}</li>
            </ol>
            <p class="small">For more information, consult <a href="https://iabeurope.eu/iab-europe-transparency-consent-framework-policies" target="_blank">iabeurope.eu</a>.</p>
        </b-modal>
        <b-modal id="modal-features" title="List of Features" ok-only ok-title="Close">
            <ol>
                <li v-for="f in gvl.features" :key="'f-' + f.id">{{ f.name }}</li>
            </ol>
            <p class="small">For more information, consult <a href="https://iabeurope.eu/iab-europe-transparency-consent-framework-policies" target="_blank">iabeurope.eu</a>.</p>
        </b-modal>
        <b-modal id="modal-special-features" title="List of Special Features" ok-only ok-title="Close">
            <ol>
                <li v-for="sf in gvl.specialFeatures" :key="'sf-' + sf.id">{{ sf.name }}</li>
            </ol>
            <p class="small">For more information, consult <a href="https://iabeurope.eu/iab-europe-transparency-consent-framework-policies" target="_blank">iabeurope.eu</a>.</p>
        </b-modal>
    </section>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import ConsentPublisherRestrictions from './consent-publisher-restrictions';

export default {
    name: 'consent-vendors',
    components: {
        ConsentPublisherRestrictions
    },
    props: {
        title: {
            required: true,
            type: String
        },
        subtitle: {
            required: true,
            type: String
        },
        vendors: {
            required: true
        },
        custom: {
            type: Boolean,
            required: false,
            default: false
        },
        gvl: {
            required: true
        },
        customPurposes: {
            required: true
        }
    },
    data() {
        return {
            error: null,

            showAll: false,

            /* Flag used to re-render the component after vendor restrictions have been changed */
            update: 0,

            currentVendor: null
        }
    },
    async mounted() {
        /* If publisher restrictions modal closed, re-render this component to update 'Restricted' links */
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if (modalId === 'modal-publisher-restrictions') {
                this.update++;
                this.currentVendor = null;
            }
        });
    },
    watch: {
        selectedVendors(selected) {
            /**
             * Check for each selected vendor whether all custom purposes are enabled. If not, select the custom purpose.
             */
            selected.forEach((vendorId) => {
                const vendor = this.vendors[vendorId];
                if (vendor) {
                    /* this.vendors might only include custom vendors (on Custom Vendors tab), while selected also contains IAB vendors */
                    Object.keys(this.customPurposes).forEach((cpId) => {
                        const cp = this.customPurposes[cpId];
                        if ((vendor.purposes.includes(cp.id) || vendor.legIntPurposes.includes(cp.id)) && !this.selectedCustomPurposes.includes(cp.id)) {
                            this.$store.commit('addCustomPurpose', cp.id);
                            this.$bvToast.toast(`Enabled custom purpose ${cp.id} because ${vendor.name} depends on it.`, {
                                title: 'Consent',
                                autoHideDelay: 5000,
                                solid: true
                            });
                        }
                    });
                }
            });
        }
    },
    computed: {
        ...mapFields(['project', 'selectedVendors', 'selectedCustomPurposes', 'publisherRestrictions']),
        filteredVendors() {
            if (this.showAll) {
                return this.sortVendors(this.vendors);
            } else {
                const result = {};
                Object.keys(this.vendors).forEach((id) => {
                    if (this.selectedVendors.includes(parseInt(id))) {
                        result[id] = this.vendors[id];
                    }
                });

                return this.sortVendors(result);
            }
        }
    },
    methods: {
        sortVendors(vendors) {
            const result = [];
            for (const vendor in vendors) {
                result.push(vendors[vendor]);
            }
            result.sort((a, b) => {
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0);
            });
            return result;
        },

        hasRestrictions(vendor) {
            const publisherRestrictions = this.publisherRestrictions[vendor.id] || {};
            return Object.keys(publisherRestrictions).filter((key) => { return publisherRestrictions[key] !== null }).length > 0;
        }
    }
}
</script>