<template>
    <main>
        <analysis-header title="Journey Analytics"></analysis-header>

        <section class="container-fluid my-3">
            <b-alert v-if="error" :show="true" variant="danger">{{ error }}</b-alert>
            <b-alert v-if="available === false" variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null}}</strong>.</b-alert>

            <div v-else-if="available" class="journey-analytics-grid">
                <b-card>
                    <b-card-title>
                        <span>Touchpoints</span>
                        <i class="bx bxs-help-circle" v-b-tooltip title="Shows the amount of touchpoints in a journey. A touchpoint can be a click on a link or view of a display banner"></i>
                    </b-card-title>
                    <div v-if="touchPointsError" class="alert alert-danger">{{ journeyAgeError }}</div>
                    <donut v-else name="Touchpoints" :data="touchPointsToShow"></donut>
                </b-card>

                <b-card>
                    <b-card-title>
                        <span>Journey Age</span>
                        <i class="bx bxs-help-circle" v-b-tooltip title="Shows the amount of journeys per journey age in days"></i>
                    </b-card-title>
                    <div v-if="journeyAgeError" class="alert alert-danger">{{ journeyAgeError }}</div>
                    <donut v-else name="Days" :data="journeyAgeToShow"></donut>
                </b-card>

                <b-card>
                    <b-card-title>
                        <span>Channel Position</span>
                        <i class="bx bxs-help-circle" v-b-tooltip title="Shows the occurrences a channel was the first, last, assisting or single touchpoint in a journey Single touchpoints are the only touchpoint in a journey. Assisting touchpoints are all touchpoints that are not the first or last in a journey."></i>
                    </b-card-title>
                    <donut name="Channel Position" :data="channelPositionsToShow"></donut>
                    <b-form-group>
                        <b-form-radio-group
                            id="position"
                            v-model="position"
                            :options="[{
                                text: 'Single', value: 'single_sessions'
                            }, {
                                text: 'First', value: 'first_sessions'
                            }, {
                                text: 'Assist', value: 'assist_sessions'
                            }, {
                                text: 'Last', value: 'last_sessions'
                            }]"
                            buttons
                            name="radios-btn-default"
                        ></b-form-radio-group>
                    </b-form-group>
                </b-card>
            </div>
        </section>
    </main>
</template>
<script>
import AnalysisHeader from './analysis-header';
import Donut from '../donut';
import {postData} from "@/lib/load_data";
import {mapState} from "vuex";



export default {
    name: 'analysis-journey-analytics',
    components: {
        AnalysisHeader,
        Donut
    },
    data() {
        return {
            error: null,
            position: 'single_sessions',
            channelPositions: []
        };
    },
    watch: {
        project() {
            this.queryChannelPositions();
        }
    },
    computed: {
        ...mapState(['project', 'products', 'segments']),
        available() {
            return this.products !== null ? this.products.includes('analytics') : null;
        },
        touchPointsError() {
            return this.segments.length === 0 ? 'No segments selected' : null;
        },
        touchPointsToShow() {
            return [
                { name: '1',       value: (this.segments.includes('successful') ?   10 : 0) + (this.segments.includes('unsuccessful') ? 8250412 : 0) },
                { name: '2',       value: (this.segments.includes('successful') ? 1236 : 0) + (this.segments.includes('unsuccessful') ? 2001224 : 0) },
                { name: '3',       value: (this.segments.includes('successful') ?  872 : 0) + (this.segments.includes('unsuccessful') ?  857497 : 0) },
                { name: '4',       value: (this.segments.includes('successful') ?  706 : 0) + (this.segments.includes('unsuccessful') ?  467839 : 0) },
                { name: '5',       value: (this.segments.includes('successful') ?  515 : 0) + (this.segments.includes('unsuccessful') ?  287183 : 0) },
                { name: '6-10',    value: (this.segments.includes('successful') ? 1496 : 0) + (this.segments.includes('unsuccessful') ?  556768 : 0) },
                { name: '11-20',   value: (this.segments.includes('successful') ? 1075 : 0) + (this.segments.includes('unsuccessful') ?  224238 : 0) },
                { name: '21-50',   value: (this.segments.includes('successful') ?  694 : 0) + (this.segments.includes('unsuccessful') ?   82567 : 0) },
                { name: '51-100',  value: (this.segments.includes('successful') ?  244 : 0) + (this.segments.includes('unsuccessful') ?   11443 : 0) },
                { name: '101-200', value: (this.segments.includes('successful') ?   48 : 0) + (this.segments.includes('unsuccessful') ?     540 : 0) },
                { name: '200+',    value: (this.segments.includes('successful') ?   27 : 0) + (this.segments.includes('unsuccessful') ?     212 : 0) }
            ]
        },

        journeyAgeError() {
            return this.segments.length === 0 ? 'No segments selected' : null;
        },
        journeyAgeToShow() {
            return [
                { name: '<1',    value: (this.segments.includes('successful') ? 1884 : 0) + (this.segments.includes('unsuccessful') ? 9399145 : 0) },
                { name: '<2',    value: (this.segments.includes('successful') ?  260 : 0) + (this.segments.includes('unsuccessful') ?  187750 : 0) },
                { name: '<5',    value: (this.segments.includes('successful') ?  420 : 0) + (this.segments.includes('unsuccessful') ?  286589 : 0) },
                { name: '<10',   value: (this.segments.includes('successful') ?  296 : 0) + (this.segments.includes('unsuccessful') ?  288189 : 0) },
                { name: '<30',   value: (this.segments.includes('successful') ?  530 : 0) + (this.segments.includes('unsuccessful') ?  600434 : 0) },
                { name: '<50',   value: (this.segments.includes('successful') ?  308 : 0) + (this.segments.includes('unsuccessful') ?  341615 : 0) },
                { name: '<100',  value: (this.segments.includes('successful') ?  563 : 0) + (this.segments.includes('unsuccessful') ?  488165 : 0) },
                { name: '<200',  value: (this.segments.includes('successful') ?  723 : 0) + (this.segments.includes('unsuccessful') ?  478089 : 0) },
                { name: '200+',  value: (this.segments.includes('successful') ? 1939 : 0) + (this.segments.includes('unsuccessful') ?  670994 : 0) }
            ]
        },

        channelPositionsToShow() {
            const result = [];
            for (let i = 0; i < this.channelPositions.length; i++) {
                result.push({
                    name: this.channelPositions[i].marketing_channel,
                    value: this.channelPositions[i][this.position]
                });
            }
            
            return result;
        }
    },
    mounted() {
        this.queryChannelPositions();
    },
    methods: {
        async queryChannelPositions() {
            if (this.project) {
                this.channelPositions = await postData('/api/analysis/channel-positions', {
                    project: this.project.name
                });
            }
        }
    }
}
</script>