<template>
    <div class="container">
        <div class="row my-5">
            <div class="col-12 col-sm-10 offset-sm-2">
                <h1>Change your password</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-8 offset-sm-2">
                <div v-if="message !== null">
                    <b-alert variant="success" :show="true" v-if="message !== null" v-html="message"></b-alert>
                    <p class="my-3">
                        <router-link to="/login" custom v-slot="{ href, navigate }">
                            <a :href="href" @click="navigate" class="btn btn-lg btn-primary">Login</a>
                        </router-link>
                    </p>
                </div>
                <div v-else>
                    <p>Enter a new password for your account for <strong>console.onesecondbefore.com</strong>.</p>
                    <b-alert variant="danger" :show="true" v-if="error !== null">
                        <i class="bx bx-error"></i>
                        {{ error }}
                    </b-alert>
                    <form action="#" method="POST" v-if="token !== null">
                        <!-- Hidden username field to help password managers https://www.chromium.org/developers/design-documents/create-amazing-password-forms -->
                        <input type="hidden" name="username" :value="email" autocomplete="username">
                        <text-input name="password1" v-model="password1" type="password" label="New password" rules="required" @change="error = null" autocomplete="new-password"/>
                        <text-input name="password2" v-model="password2" type="password" label="Repeat new password" rules="required" @change="error = null" autocomplete="new-password"/>
                        <button class="btn btn-lg btn-primary" @click.prevent="sendPasswordUpdate" :disabled="error !== null">Update password</button>
                    </form>
                    <p class="my-3">
                        <router-link to="/login">Back to login</router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {postDataUnauthorized} from "@/lib/load_data";
import TextInput from '@/components/text-input';

export default {
    name: 'change-password',
    components: {
        TextInput
    },
    data() {
        return {
            email: null,
            password1: null,
            password2: null,
            message: null,
            error: null,
            token: null
        }
    },
    mounted() {
        this.token = this.$route.params.token;
        if (this.token) {
            this.email = JSON.parse(window.atob(this.token.split('.')[1])).email;
        } else {
            this.error = 'No token';
        }
    },
    methods: {
        async sendPasswordUpdate() {
            this.error = null;
            this.message = null;

            if (this.password1 === this.password2) {
                if (this.password1.length >= 8) {
                    const response = await postDataUnauthorized('/api/change-password', {
                        token: this.token,
                        password: this.password1
                    });
                    if (response.error) {
                        this.error = response.error;
                    } else {
                        this.message = response.message;
                    }
                } else {
                    this.error = 'Password should be at least 8 characters long';
                }
            } else {
                this.error = 'Passwords are not equal';
            }
        }
    }
}
</script>
