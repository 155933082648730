import {formatCurrency, formatDate, formatDuration, formatNumber, formatPercentage, formatUnits } from "@/lib/formatting";

export default {
    methods: {
        formatNumber(value, digits = 0) {
            return formatNumber(value, digits);
        },
        formatPercentage(value) {
            return formatPercentage(value);
        },
        formatCurrency(value) {
            return formatCurrency(value);
        },
        formatDate(value, pattern) {
            return formatDate(value, pattern);
        },
        formatDuration(value) {
            return formatDuration(value);
        },
        formatUnits(value) {
            return formatUnits(value);
        }
    }
}
