<template>
    <div class="tiptap-editor">
        <div v-if="editor" class="mb-2">
            <button type="button" @click="editor.chain().focus().toggleBold().run()" :class="{ 'btn': true, 'is-active': editor.isActive('bold') }" v-b-tooltip title="Bold">
                <i class="bx bx-bold"></i>
            </button>
            <button type="button" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'btn': true, 'is-active': editor.isActive('italic') }" v-b-tooltip title="Italic">
                <i class="bx bx-italic"></i>
            </button>
            <button type="button" @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'btn': true, 'is-active': editor.isActive('underline') }" v-b-tooltip title="Underline">
                <i class="bx bx-underline"></i>
            </button>
            <button type="button" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'btn': true, 'is-active': editor.isActive('strike') }" v-b-tooltip title="Strike-through">
                <i class="bx bx-strikethrough"></i>
            </button>
            <button type="button" @click="editor.chain().focus().toggleCode().run()" :class="{ 'btn': true, 'is-active': editor.isActive('code') }" v-b-tooltip title="Code">
                <i class="bx bx-code-alt"></i>
            </button>
            <button type="button" @click="editor.chain().focus().setParagraph().run()" :class="{ 'btn': true, 'is-active': editor.isActive('paragraph') }" v-b-tooltip title="Paragraph">
                <i class="bx bx-paragraph"></i>
            </button>

            <b-dropdown text="Header" variant="primary">
                <b-dropdown-item @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :active="editor.isActive('heading', { level: 1 })">
                    Header 1
                </b-dropdown-item>
                <b-dropdown-item @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :active="editor.isActive('heading', { level: 2 })">
                    Header 2
                </b-dropdown-item>
                <b-dropdown-item @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :active="editor.isActive('heading', { level: 3 })">
                    Header 3
                </b-dropdown-item>
                <b-dropdown-item @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :active="editor.isActive('heading', { level: 4 })">
                    Header 4
                </b-dropdown-item>
                <b-dropdown-item @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :active="editor.isActive('heading', { level: 5 })">
                    Header 5
                </b-dropdown-item>
                <b-dropdown-item @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :active="editor.isActive('heading', { level: 6 })">
                    Header 6
                </b-dropdown-item>
            </b-dropdown>

            <button type="button" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'btn': true, 'is-active': editor.isActive('bulletList') }" v-b-tooltip title="Unordered list">
                <i class="bx bx-list-ul"></i>
            </button>
            <button type="button" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'btn': true, 'is-active': editor.isActive('orderedList') }" v-b-tooltip title="Ordered list">
                <i class="bx bx-list-ol"></i>
            </button>
            <button type="button" @click="editor.chain().focus().setHardBreak().run()" class="btn">
                hard break
            </button>
            <button type="button" @click="editor.chain().focus().undo().run()" class="btn" v-b-tooltip title="Undo">
                <i class="bx bx-undo"></i>
            </button>
            <button type="button" @click="editor.chain().focus().redo().run()" class="btn" v-b-tooltip title="Redo">
                <i class="bx bx-repeat"></i>
            </button>
            <button type="button" @click="toggleMode" :class="{'btn': true, 'is-active': mode === 'source'}" v-b-tooltip title="View source">
                <i class="bx bx-code-alt"></i>
            </button>
        </div>
        <editor-content v-if="mode === 'text'" :editor="editor"></editor-content>
        <textarea v-else-if="mode === 'source'" v-model="raw" class="w-100 source-code" placeholder="HTML source" rows="5"></textarea>
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link'

export default {
    components: {
        EditorContent,
    },

    props: {
        value: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            editor: null,
            raw: null,
            mode: 'text'
        }
    },

    watch: {
        value(value) {
            // HTML
            const isSame = this.editor.getHTML() === value;

            // JSON
            // const isSame = this.editor.getJSON().toString() === value.toString()

            if (isSame) {
                return;
            }

            this.editor.commands.setContent(this.value, false);
            this.raw = this.editor.getHTML();
        }
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit,
                Link.configure({
                    HTMLAttributes: {
                        rel: null,
                        target: null
                    }
                }),
                Underline
            ],
            content: this.value,
            onUpdate: () => {
                // HTML
                this.$emit('input', this.editor.getHTML());

                // JSON
                // this.$emit('input', this.editor.getJSON())
            },
        })
    },

    methods: {
        toggleMode() {
            if (this.mode === 'text') {
                this.raw = this.editor.getHTML();
                this.mode = 'source';
            } else {
                this.editor.commands.setContent(this.raw, false);
                this.$emit('input', this.raw);
                this.mode = 'text';
            }
            return false;
        }
    },

    beforeDestroy() {
        this.editor.destroy()
    },
}
</script>