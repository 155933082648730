<template>
    <main>
        <div class="analysis-nav d-flex justify-content-between align-items-center">
            <h1>Management &middot; <span class="green">Analytics</span></h1>
        </div>
        <section v-if="error" class="container-fluid">
            <b-alert :show="true" variant="danger">{{ error }}</b-alert>
        </section>
        <section v-if="available === false" class="container-fluid">
            <b-alert variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null}}</strong>.</b-alert>
        </section>

        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-xl-4">
                    <text-input name="trackerUrl" v-model="trackerUrl" label="Tracker URL" prepend="https://"></text-input>
                    <text-input name="siteId" v-model="siteId" label="Site ID"></text-input>

                    <div class="mb-3">
                        <b-form-checkbox name="debug" value="true" v-model="debug">Enable debugging</b-form-checkbox>
                        <b-form-checkbox name="includePageview" value="true" v-model="includePageview">Include pageview event</b-form-checkbox>
                    </div>

                    <b-button variant @click="preview">Preview <i class="bx bx-link-external"></i></b-button>
                </div>

                <b-tabs v-if="project" class="col-12 col-xl-8">
                    <b-tab title="Client script" lazy>
                        <source-code lang="html">
                            {{ code0 }}
                        </source-code>
                    </b-tab>
                    <b-tab title="Include script" lazy>
                        <source-code lang="html">
                            {{ code1 }}
                        </source-code>
                    </b-tab>
                    <b-tab title="Single line" lazy>
                        <source-code lang="html">
                            {{ code2 }}
                        </source-code>
                    </b-tab>
                    <b-tab title="Google Tag Manager">
                        <source-code lang="html">
                            {{ code3 }}
                        </source-code>
                        <p class="text-muted small">This code fragment does not allow <code>crossorigin="use-credentials"</code> and hence will use a redirect to retrieve the cross-domain user-id.</p>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </main>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import TextInput from '../../text-input';
import SourceCode from '../../source-code';
import {ENDPOINT_URL} from "@/config";

export default {
    name: 'capture',
    components: {
        TextInput,
        SourceCode
    },
    data() {
        return {
            error: null,
            message: null,
            
            trackerUrl: 'c.onesecondbefore.com',
            siteId: null,
            includePageview: false,
            debug: false
        };
    },
    computed: {
        ...mapFields(['project', 'products']),
        available() {
            return this.products !== null ? this.products.includes('capture') : null;
        },
        code0() {
            let code = "// For Google Tag Manager\n" +
                "<script>\n" +
                "(function(o,n,e,s,b,f){osb_queue=n.osb_queue||[];osb=n.osb||function(...args){osb_queue.push(args)};\n" +
                "f=o.createElement('script');f.async=true;f.src=e+'/client/'+s+(b?'/'+b:'')+'.js';\n" +
                "o.currentScript.parentNode.insertBefore(f,o.currentScript.nextSibling);\n" +
                "})(document,window,'https://" + this.trackerUrl + "','" + this.project.name + "', " + (this.siteId && this.siteId.trim().length > 0 ? "'" + this.siteId.trim() + "'" : "null")+ ");\n";
            if (this.debug) {
                code += "osb('debug',true);\n";
            }
            if (this.includePageview) {
                code += "osb('send','pageview');\n";
            }
            code += "</scr" + "ipt>\n\n";

            const qs = new URLSearchParams();
            if (this.debug) {
                qs.set("debug", "1");
            }
            if (this.includePageview) {
                qs.set("send", "pageview");
            }
            code += "// Or, to include in the HTML source\n" +
                "<script crossorigin=\"use-credentials\" src=\"https://" + this.trackerUrl + "/client/" +
                    encodeURIComponent(this.project.name) + (this.siteId && this.siteId.trim().length > 0 ? "/" + this.siteId.trim() : "") + ".js" +
                    (qs.size > 0 ? "?" + qs.toString() : "") +
                    "\"></scr" + "ipt>\n";

            return code;
        },

        code1() {
            let code = "<script type=\"module\" crossorigin=\"use-credentials\">\n";
            code += "import osb from 'https://" + this.trackerUrl + "/js/osb.min.mjs';\n";
            if (this.debug) {
                code += "osb('debug',true);\n";
            }
            code += "osb('config',{";
            code += "accountId:'" + this.project.name + "'";
            if (this.siteId && this.siteId.trim().length > 0) {
                code += ",siteId:'" + this.siteId.trim() + "'";
            }
            code += "});\n";
            if (this.includePageview) {
                code += "osb('send','pageview');\n";
            }
            code += "</scr" + "ipt>\n";
            return code;
        },

        code2() {
            return "<script type=\"module\" crossorigin=\"use-credentials\"\n" +
                "         src=\"https://" + this.trackerUrl + "/js/osb.min.mjs?aid=" + encodeURIComponent(this.project.name) +
                (this.siteId && this.siteId.trim().length > 0 ? "&sid=" + encodeURIComponent(this.siteId.trim()) : "") +
                (this.debug ? "&debug=1" : "") +
                (this.includePageview ? "&send=pageview" : "") +
                "\">\n</scr" + "ipt>";
        },

        code3() {
            let code =
                "<script>\n(function(w,d,c,u,n,a,s,dbg,pv){w[n+'_queue']=w[n+'_queue']||[];w[n]=function(){w[n+'_queue'].push\n" +
                "(Array.prototype.slice.call(arguments))};var f=d.getElementsByTagName(c)[0],j=d.createElement(c),\n" +
                "l='noModule' in j;if(l){j.async=true;j.type='module'}j.src=u+'/js/osb.min.'+(l?'mjs':'js')+'?aid='+a+\n" +
                "(n&&n!=='osb'?'&name='+n:'')+(s?'&sid='+s:'')+(dbg?'&debug=1':'')+(pv?'&send=pageview':'')\n" +
                "f.parentNode.appendChild(j);})\n";
            code += "(window,document,'script','https://" + this.trackerUrl + "','osb','" + encodeURIComponent(this.project.name) + "'," +
                (this.siteId && this.siteId.trim().length > 0 ? "'" + encodeURIComponent(this.siteId.trim()) + "'" : "null") +
                "," + this.debug + "," + this.includePageview + ");\n";
            code += "</scr" + "ipt>";
            return code;
        }
    },
    methods: {
        preview() {
            const search = new URLSearchParams();
            this.trackerUrl && search.set('url', 'https://' + this.trackerUrl);
            this.siteId && search.set('sid', this.siteId);
            this.debug && search.set('debug', this.debug);
            this.includePageview && search.set('send', 'pageview');
            window.open(ENDPOINT_URL + '/capture/preview/' + this.project.name + '?' + search.toString(), '_capture_preview', 'popup=1,noreferrer=1,width=800,height=350');
        }
    }
}
</script>