<template>
    <div class="container">
        <div class="row my-5">
            <div class="col-12 col-md-10 offset-md-2">
                <h1>Keep track</h1>
            </div>
            <div class="col-12 col-md-6 offset-md-2">
                <p>Login to the Onesecondbefore console to get insights and manage various products and services.</p>
                <p>
                    <router-link to="/login" custom v-slot="{ href, navigate }">
                        <a class="btn btn-lg btn-primary" :href="href" @click="navigate">Login</a>
                    </router-link>
                </p>
                <p>Don't have an account? <a href="https://www.onesecondbefore.com/contact">Contact us</a>.</p>
            </div>
            <div class="col-md-3 d-none d-md-block">
                <img src="/images/welcome.svg" class="img-fluid" alt="Welcome image"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'welcome'
}
</script>
