<template>
    <main>
        <div class="analysis-nav">
            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <h1>Management &middot; <span class="green">Workflows</span></h1>
                    <div class="d-flex align-items-center" v-if="available">
                        <router-link :to="'/management/transfer/tasks/' + job" custom v-slot="{ href, navigate }">
                            <a :href="href" :title="'Back to task runs per day for ' + job" @click="navigate" class="btn btn-outline-secondary"><i class="bx bx-chevron-left"></i> Back to task runs per day</a>
                        </router-link>

                        <b-dropdown :html="'<strong>Job:</strong> ' + job" variant="primary" class="ml-3" menu-class="dropdown80" :no-flip="true">
                            <b-dropdown-item v-for="j in jobsOverview" @click="selectJob(j.job)" :key="'j-' + j.job" :active="job === j.job">
                                {{ j.job }}
                            </b-dropdown-item>
                        </b-dropdown>

                        <b-dropdown :html="'<strong>Task:</strong> ' + task" variant="primary" class="mx-3" menu-class="dropdown80" :no-flip="true">
                            <b-dropdown-item v-for="t in tasksOverview" @click="selectTask(t.task_id)" :key="'t-' + t.task_id" :active="task === t.task_id">
                                {{ t.task_id }}
                            </b-dropdown-item>
                        </b-dropdown>
                        <month-selector v-model="date"></month-selector>
                    </div>
                </div>
            </div>
        </div>

        <section class="container-fluid">
            <b-alert v-if="error !== null" variant="danger" :show="true">{{ error }}</b-alert>
            <b-alert v-if="available === false" variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null}}</strong>.</b-alert>

            <div v-else-if="taskRuns" class="card">
                <div class="card-body">
                    <h4 class="card-title">
                        <span>Task <span class="green">{{ task }}</span> &middot; Runs</span>
                        <i class="bx bxs-help-circle" v-b-tooltip title="All information about individual task runs"></i>
                    </h4>

                    <b-table id="jobs-overview-table" bordered class="mt-3" :fixed="true" :items="taskRuns" :per-page="pageSize" sort-by="start_date" :sort-desc.sync="sortDesc" :current-page="currentPage" :busy="busy" :responsive="true" :fields="[
                        { key: 'group', label: '', class: 'group' },
                        { key: 'run_id' },
                        { key: 'start_date', sortable: true, sortDirection: 'desc' },
                        { key: 'log_link', label: '' },
                        { key: 'state' },
                        { key: 'attempt', class: 'text-right' },
                        { key: 'duration', class: 'text-right' }
                    ]">
                        <template v-slot:table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                        </template>
                        <template v-slot:table-colgroup>
                            <col style="width: 16px">
                            <col style="width: 10%">
                            <col style="width: 48%">
                            <col style="width: 10%">
                            <col style="width: 10%">
                            <col style="width: 10%">
                            <col style="width: 12%">
                        </template>
                        <template v-slot:head(run_id)="">
                            Run ID <i class="bx bxs-help-circle" v-b-tooltip title="Unique ID for the run (used to bundle retries of the same task run"></i>
                        </template>
                        <template v-slot:head(start_date)="">
                            Start Date <i class="bx bxs-help-circle" v-b-tooltip title="Timestamp when the task was started"></i>
                        </template>
                        <template v-slot:head(log_link)="">
                            Log file <i class="bx bxs-help-circle" v-b-tooltip title="Show the log of this particular task instance run"></i>
                        </template>
                        <template v-slot:head(state)="">
                            State <i class="bx bxs-help-circle" v-b-tooltip title="State of the task"></i>
                        </template>
                        <template v-slot:head(attempt)="">
                            Attempt <i class="bx bxs-help-circle" v-b-tooltip title="Attempt this task was executed. Change the task configuration if a particularly task has to make several retries"></i>
                        </template>
                        <template v-slot:head(duration)="">
                            Duration <i class="bx bxs-help-circle" v-b-tooltip title="Duratio of the task execution in seconds. Is only available for tasks that are in the success, failed or up for retry state"></i>
                        </template>
                        <template v-slot:cell(group)="data">
                            <span :class="{
                                'group0': data.item.c === 1 && data.item.r === 1,
                                'group1': data.item.c > 1 && ((!sortDesc && data.item.r === 1) || (sortDesc && data.item.r === data.item.c)),
                                'group2': data.item.c > 1 && data.item.r > 1 && data.item.r < data.item.c,
                                'group3': data.item.c > 1 && ((!sortDesc && data.item.r === data.item.c) || (sortDesc && data.item.r === 1))
                            }"></span>
                        </template>
                        <template v-slot:cell(start_date)="data">{{ formatDate(data.item.start_date, 'YYYY-MM-DD HH:mm:ss') }}</template>
                        <template v-slot:cell(log_link)="data">
                            <i class="bx bxs-download mr-2"></i>
                            <a href="#" @click="showLog(data.item.execution_date, data.item.attempt)" title="Download log file">Log</a>
                        </template>
                        <template v-slot:cell(duration)="data">{{ formatDuration(data.item.duration) }}</template>
                    </b-table>
                    <b-pagination v-if="taskRuns.length > pageSize"
                                  v-model="currentPage"
                                  :total-rows="taskRuns.length"
                                  :per-page="pageSize"
                                  aria-controls="jobs-overview-table"
                    ></b-pagination>
                </div>
            </div>
            <b-modal v-if="log !== null" v-model="showLogModal" :ok-only="true" :title="logModalTitle" size="xl" scrollable>
                <pre>
                    <code>{{ log }}</code>
                </pre>
                <template v-slot:modal-footer="{ ok }">
                    <b-button variant="success" @click="downloadBlob">
                        <i class="bx bxs-download"></i> Download
                    </b-button>
                    <b-button variant="success" @click="ok()">
                        Close
                    </b-button>
                </template>
            </b-modal>
        </section>
    </main>
</template>
<script>
import MonthSelector from '../../month-selector';
import { postData, downloadData } from '@/lib/load_data';
import { formatUtcDate, formatDate } from "@/lib/formatting";
import { saveAs } from 'file-saver';
import { mapFields } from "vuex-map-fields";
import BaseFormatting from "@/components/base-formatting.mjs";

export default {
    name: 'transfer-task-list',
    mixins: [
        BaseFormatting
    ],
    components: {
        MonthSelector
    },
    data() {
        return {
            job: null,
            task: null,
            taskRuns: [],
            tasksOverview: [],
            jobsOverview: [],
            currentPage: 1,
            pageSize: 50,
            busy: false,
            error: null,

            sortDesc: true,

            blob: null,
            log: null,
            showLogModal: false,
            logModalTitle: null,
            logFilename: null
        }
    },
    async mounted() {
        this.job = this.$route.params.jobName;
        this.task = this.$route.params.taskName;
        await Promise.all([
            this.loadJobs(),
            this.loadTaskNamesPerJob(),
            this.loadTaskRuns(),
        ]);
    },
    watch: {
        async project() {
            await Promise.all([
                this.loadJobs(),
                this.loadTaskNamesPerJob(),
                this.loadTaskRuns(),
            ]);
        },
        async date() {
            await Promise.all([
                this.loadJobs(),
                this.loadTaskNamesPerJob(),
                this.loadTaskRuns(),
            ]);
        }
    },
    computed: {
        ...mapFields(['project','date','products']),
        available() {
            return this.products !== null ? this.products.includes('transfer') : null;
        }
    },
    methods: {
        async loadJobs() {
            this.error = null;
            this.jobsOverview = await postData('/api/transfer/jobs/overview', {
                project: this.project.name,
                date: formatDate(this.date, 'YYYY-MM-DD')
            });
        },
        async loadTaskNamesPerJob() {
            this.error = null;
            this.tasksOverview = await postData('/api/transfer/tasks/overview', {
                project: this.project.name,
                job: this.job,
                date: formatDate(this.date, 'YYYY-MM-DD')
            });
            if (this.tasksOverview.length === 0) {
                this.error = 'There are no task runs for this time period.';
            }
        },
        async loadTaskRuns() {
            this.error = null;
            this.taskRuns = await postData('/api/transfer/tasks/list', {
                project: this.project.name,
                job: this.job,
                task: this.task,
                date: formatDate(this.date, 'YYYY-MM-DD')
            });
        },
        async showLog(execution_date, attempt) {
            this.error = null;
            try {
                this.blob = await downloadData('/api/transfer/log/' + this.project.name +
                    '/' + this.job +
                    '/' + this.task +
                    '/' + formatUtcDate(execution_date, 'YYYY-MM-DD[T]HH:mm:ssZ') +
                    '/' + attempt);
                if (this.blob !== null) {
                    this.log = await this.blob.text();
                    this.showLogModal = true;
                    this.logModalTitle = 'Log file for ' + this.task + ' @ ' + formatUtcDate(execution_date, 'YYYY-MM-DD HH:mm:ss' + ' / ' + attempt);
                    this.logFilename = this.task + '_' + formatUtcDate(execution_date, 'YYYYMMDD_HHmmss') + '_' + attempt + '.log';
                }
            } catch (ex) {
                this.error = ex.message;
            }

            return false;
        },
        downloadBlob() {
            saveAs(this.blob, this.logFilename);
        },
        async selectJob(job) {
            if (this.job !== job) {
                this.job = job;
                await this.loadTaskNamesPerJob();
                this.task = this.tasksOverview[0].task_id;
                await this.loadTaskRuns();
                this.$router.push('/management/transfer/tasks/' + job + '/' + this.task);
            }
        },
        selectTask(task) {
            if (this.task !== task) {
                this.task = task;
                this.loadTaskRuns();
                this.$router.push('/management/transfer/tasks/' + this.job + '/' + task);
            }
        }
    }
}
</script>