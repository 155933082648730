<template>
    <div class="container">
        <div class="row my-5">
            <div class="col-12 col-sm-10 offset-sm-2">
                <h1>Please login to continue</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-8 offset-sm-2">
                <b-alert variant="danger" v-if="error !== null" :show="true" dismissible>
                    <i class="bx bx-error"></i>
                    {{ error }}
                </b-alert>
                <div id="login-with-google-button"></div>
                <form action="#" method="POST" @submit.prevent="loginUsername()">
                    <text-input name="username" v-model="username" label="E-mail address" type="email" rules="required" autocomplete="username"></text-input>
                    <text-input name="password" v-model="password" label="Password" type="password" rules="required" autocomplete="current-password"></text-input>
                    <button class="btn btn-lg btn-primary">Login</button>
                </form>
                <p class="my-3">
                    <router-link to="/forgot">Forgot your password?</router-link>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from "vuex";
import emitter from '../lib/emitter';
import { ENDPOINT_URL } from '@/config';
import TextInput from '@/components/text-input';

export default {
    name: 'login',
    components: {
        TextInput
    },
    data() {
        return {
            username: null,
            password: null,
            error: null,
        }
    },
    computed: {
        ...mapState(['token','email','picture','permissions'])
    },
    created() {
    },
    mounted() {
        this.addGoogleScript((google) => {
            if (google) {
                google.accounts.id.initialize({
                    client_id: '905992749865-5htp5btu889ngm9d3m3mtenr5810b29k.apps.googleusercontent.com',
                    ux_mode: 'popup',
                    theme: 'filled_blue',
                    callback: this.submitLogin,
                    locale: 'en_US',
                    use_fedcm_for_prompt: true,
                });
                google.accounts.id.renderButton(document.getElementById('login-with-google-button'), {
                    theme: 'filled_blue',
                    size: 'large',
                });
            } else {
                this.error = 'Cannot load "Login with Google". Please reload this page';
            }
        })
    },
    methods: {
        addGoogleScript(callback) {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.defer = true;
            script.addEventListener('load', () => {
                callback(window.google)
            });
            document.querySelector('body').appendChild(script);
        },

        async submitLogin(data) {
            this.error = null;

            emitter.emit('spinner:show');
            return await fetch(ENDPOINT_URL + '/api/login', {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then((response) => {
                emitter.emit('spinner:hide');
                return response.json();
            }).then((json) => {
                if (json.error) {
                    this.error = json.error;
                    return false;
                } else {
                    this.$store.commit('login', {
                        /* Our JWT token */
                        token: json.token,
                        picture: json.picture
                    });
                    if (this.$route.query.target) {
                        this.$router.push(this.$route.query.target);
                    } else {
                        this.$router.push('/homepage');
                    }
                    return true;
                }
            }).catch((error) => {
                emitter.emit('spinner:hide');
                this.error = error.message;
                return false;
            });
        },
        loginUsername() {
            this.submitLogin({
                username: this.username,
                password: this.password
            });
        }
    }
}
</script>
