<template>
    <main>
        <div class="analysis-nav">
            <div class="analysis-header">
                <h1>Analysis &middot; <span class="green">Data-Driven Micro Attribution</span></h1>
                <button class="btn btn-secondary" v-if="channel !== null" @click="channel = null">
                    <i class="bx bx-chevron-left"></i>
                    Back
                </button>
            </div>
        </div>

        <section class="container-fluid my-3">
            <b-alert v-if="error" :show="true" variant="danger">{{ error }}</b-alert>
            <b-alert v-if="available === false" variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null }}</strong>.</b-alert>

            <div v-else-if="available">
                <b-card>
                    <b-card-title v-if="!channel">
                        Attributed Revenue per Marketing Channel
                        <i class="bx bxs-help-circle ml-2" v-b-tooltip title="Attributed Revenue (€) per Marketing Channel: last touch versus data-driven micro attribution"></i>
                    </b-card-title>
                    <b-card-title v-else>
                        <span>Attributed Revenue for Marketing Channel <span class="green">{{ channel }}</span></span>
                        <i class="bx bxs-help-circle ml-2" v-b-tooltip :title="'Attributed Revenue (€) for ' + channel + ': last touch versus data-driven micro attribution'"></i>
                    </b-card-title>
                    <bar-chart :data="ddmaData" :height="500"
                               name="data-driven-micro-attribution"
                               :format-x-axis="formatXAxis"
                               y-axis="currency"
                               :x-axis="channel ? 'ads' : 'channel'"
                               :series-names="['revenue_lta','revenue_ddma']"
                               :series-label="legendFormatter"
                               :onclick="channel === null ? clickHandler : null"
                               :legend-formatter="legendFormatter">
                    </bar-chart>
                </b-card>
            </div>
        </section>
    </main>
</template>
<script>
import { mapState } from 'vuex';
import BaseFormatting from '../base-formatting.mjs';
import BarChart from '../bar-chart';
import {postData} from "@/lib/load_data";

export default {
    name: 'data-driven-micro-attribution',
    mixins: [
        BaseFormatting
    ],
    components: {
        BarChart
    },
    data() {
        return {
            error: null,
            ddmaChannels: [],
            ddmaData: [],

            channel: null
        }
    },
    computed: {
        ...mapState(['project', 'products']),
        available() {
            return this.products !== null ? this.products.includes('data-driven-micro-attribution') : null;
        }
    },
    async mounted() {
        if (this.$route.params.channel) {
            this.channel = this.$route.params.channel;
        }
        await Promise.all([
            this.loadDdmaChannels(this.project.name),
            this.loadDdmaOverview(this.project.name, this.channel)
        ]);
    },
    watch: {
        async project(newValue) {
            this.channel = null;
            await Promise.all([
                this.loadDdmaChannels(newValue.name),
                this.loadDdmaOverview(newValue.name, this.channel)
            ]);
            this.redrawChart();
        },
        async channel(newValue) {
            await this.loadDdmaOverview(this.project.name, newValue);
            this.redrawChart();
        }
    },
    methods: {
        async loadDdmaChannels(projectName) {
            this.ddmaChannels = await postData('/api/analysis/ddma/channels', {
                project: projectName
            });
        },

        async loadDdmaOverview(projectName, channel) {
            this.ddmaData = await postData('/api/analysis/ddma', {
                project: projectName,
                channel
            });
            this.redrawChart();
        },

        redrawChart() {
            /* Redraw the named barchart 'draw-echart-' + barchart name */
            window.dispatchEvent(new CustomEvent('draw-echart-data-driven-micro-attribution'));
        },

        clickHandler(params) {
            if (this.channel === null) {
                this.channel = params.name.toLowerCase();
            }
        },

        formatXAxis(d) {
            return d.length > 15 ? d.substring(0, 15) + '...' : d;
        },

        legendFormatter(name) {
            switch (name) {
                case 'revenue_lta':
                    return 'Revenue Last-Touch Attribution';
                case 'revenue_ddma':
                    return 'Revenue Data-Driven Micro Attribution';
                default:
                    return name;
            }
        }
    }
}
</script>