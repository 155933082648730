<template>
    <section>
        <b-alert v-if="error !== null" variant="danger" :show="true" dismissible>{{ error }}</b-alert>

        <ValidationObserver tag="div">
            <div class="row">
                <form class="col-12 col-md-8">
                    <b-tabs pills vertical>
                        <b-tab title="Introduction" active>
                            <h2>Introduction</h2>
                            <p>The introduction text above the list of stacks or purposes on the first page of the consent management pop-up.</p>
                            <div class="form-group">
                                <editor v-model="introduction"></editor>
                                <p class="form-text text-muted small">
                                    Specify text that you would like to show instead of the default IAB approved text.
                                    <i class="bx bxs-error-circle"
                                       v-b-tooltip="'We and our selected partners use technologies like cookies to store and/or access device information to give you the best experience. By consenting to these technologies you allow us and our partners to process personal data such as browsing behaviour and unique identifiers on this site. Not consenting or withdrawing consent, may have a negative impact on certain features and functions of this website.' +
                                        'Click on the button below to consent to list below or make specific choices, including exercising your right to object to processing personal data by our partners based on legitimate interest rather than consent. You can update your choices at any time by clicking on the privacy link on the bottom of the page.'"></i>
                                    <br>
                                    If you'd like to link to the cmp homepage use <code>&lt;a href="#page-1"&gt;homepage&lt;/a&gt;</code>,
                                    to the settings page with purposes visible, use <code>&lt;a href="#page-2"&gt;purposes&lt;/a&gt;</code>,
                                    and to the settings page with vendors visible, use <code>&lt;a href="#page-3"&gt;vendors&lt;/a&gt;</code>.
                                </p>
                                <p class="form-text text-muted small">
                                    TCF 2.2. requires a link to <code>&lt;a href="#page-3"&gt;[VENDOR_COUNT] partners&lt;/a&gt;</code> where placeholder <code>[VENDOR_COUNT]</code> will be replaced by the actual number of selected vendors.
                                </p>
                            </div>
                        </b-tab>
                        <b-tab title="No consent">
                            <h2>No consent</h2>
                            <p>Specify the text to show if no consent is given if the user closes the consent dialog without making a choice.</p>

                            <div class="form-group">
                                <b-form-checkbox id="closeButtonAllowed" v-model="closeButtonAllowed" name="closeButtonAllowed" :value="true" :unchecked-value="false">
                                    Close button enabled
                                </b-form-checkbox>
                                <span class="form-text text-muted small">Whether the close button in the top-right corner is enabled and the text below is displayed.</span>
                            </div>

                            <div v-if="closeButtonAllowed">
                                <text-input name="rejectAllTitle" v-model="rejectAllTitle" label="No consent modal pop-up title">
                                    <template v-slot:explanation><span class="form-text text-muted small">Specify title for modal dialog to show when visitor does not consent.</span></template>
                                </text-input>

                                <div class="form-group">
                                    <label class="form-label d-flex align-items-center"><span class="label">No consent modal pop-up body text</span></label>

                                    <editor v-model="rejectAllText"></editor>
                                    <span class="form-text text-muted small">Specify text why you want user to consent and what the consequences are if they don't.</span>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </form>
            </div>
        </ValidationObserver>
    </section>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import TextInput from '../../text-input';
import Editor from '../../editor';
import { ValidationObserver } from 'vee-validate';

export default {
    name: 'consent-content',
    components: {
        Editor,
        TextInput,
        ValidationObserver
    },
    data() {
        return {
            error: null
        }
    },
    computed: {
        ...mapFields(['project', 'siteId', 'token', 'introduction', 'closeButtonAllowed', 'rejectAllTitle', 'rejectAllText'])
    }
}
</script>