<template>
    <main>
        <div class="analysis-nav">
            <div class="analysis-header">
                <h1>Analysis &middot; <span class="green">Cross-Device Matching</span></h1>

                <date-range v-if="available"></date-range>
            </div>
        </div>

        <section class="container-fluid my-3">
            <b-alert v-if="error" :show="true" variant="danger">{{ error }}</b-alert>
            <b-alert v-if="available === false" variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null }}</strong>.</b-alert>

            <div v-else-if="available" class="cross-device-matching-grid">
                <score-card title="Total Hits" :value="totalHits" :color="hitsColor" tooltip="Total number of hits that were processed." id="cross-device-matching-total-hits" :refresh="refreshCMD"></score-card>
                <score-card title="Cookie Pairs" :value="avgCookiePairs" tooltip="Total number of cookie pairs that were matched. Only cookies within the same geographical area (postal code, region, country) are paired for matching. M&nbsp;=&nbsp;Million, B&nbsp;=&nbsp;Billion, T&nbsp;=&nbsp;Trillion, Q&nbsp;=&nbsp;Quadrillion" id="cross-device-matching-cookie-pairs" :format="formatUnits"></score-card>
                <score-card title="Matched Cookie Pairs" :value="totalMatchedCookies" tooltip="Total number of cookie pairs with a match score higher than the match threshold. M&nbsp;=&nbsp;Million, B&nbsp;=&nbsp;Billion, T&nbsp;=&nbsp;Trillion, Q&nbsp;=&nbsp;Quadrillion" id="cross-device-matching-total-matched" :format="formatUnits"></score-card>
                <score-card title="Match Rate" :value="totalMatchedCookies / avgCookiePairs" format="percentage" tooltip="Matched cookie pairs / total cookie pairs" id="cross-device-matching-match-rate"></score-card>

                <section class="score-card">
                    <div class="score-card-body">
                        <h4 class="card-title">
                            <span>Match Threshold</span>
                            <span>
                                <i class="bx bxs-help-circle" v-b-tooltip.html="'Minimum required match score to consider the cookie pair a match (0% is no match, 100% is a certain match, 25% is the standard threshold)'"/>
                            </span>
                        </h4>
                        <div class="d-flex align-content-center">
                            <h2 class="green w-50">
                                {{ threshold }} %
                            </h2>
                            <input type="range" min="0" max="100" class="w-100" v-model="threshold" @change="redrawChart">
                        </div>
                        <div class="small" style="line-height: 1.2">Adjust the match threshold, to recalculate the number of matched cookie pairs.</div>
                    </div>
                </section>

                <b-card class="cross-device-matching-hits">
                    <b-card-title>
                        Hits Per Day
                        <span>
                            <i class="bx bx-refresh mr-2" @click="refreshCMD()"></i>
                            <i class="bx bxs-help-circle" v-b-tooltip title="Total number of hits processed per day"></i>
                        </span>
                    </b-card-title>
                    <bar-chart :data="hitsPerDay" :height="300"
                                name="cross-device-matching-hits"
                                y-axis="count"
                                color="#45d62e"
                                series-label="Hits"
                                :show-legend="false">
                    </bar-chart>
                </b-card>

                <b-card class="cross-device-matching-day">
                    <b-card-title>
                        Cookie Pairs Per Day
                        <span>
                            <i class="bx bx-refresh mr-2" @click="refreshCMD()"></i>
                            <i class="bx bxs-help-circle" v-b-tooltip title="Number of matched cookie pairs per day for the selected date range"></i>
                        </span>
                    </b-card-title>
                    <bar-chart :data="cookiePairMatchesPerDay" :height="300" :seriesNames="['value']"
                               name="cross-device-matching-day"
                               color="#45d62e"
                               y-axis="count"
                               series-label="Cookie Pairs"
                               :zoom="true"
                               :show-legend="false">
                    </bar-chart>
                </b-card>

                <b-card class="cross-device-matching-perc">
                    <b-card-title>
                        Cookie Pairs Per Match Score (in %)
                        <span>
                            <i class="bx bx-refresh mr-2" @click="refreshCMD()"></i>
                            <i class="bx bxs-help-circle" v-b-tooltip title="Number of matched cookie pairs per match score (0 - 100%) for the selected date range"></i>
                        </span>
                    </b-card-title>
                    <bar-chart :data="cookiePairMatchesPerPerc" :height="300" :seriesNames="['value']"
                                name="cross-device-matching-perc"
                                x-axis="x"
                                y-axis="count"
                                y-axis-type="log"
                                :format-x-axis="formatPercentage"
                                :color="formatColor"
                                series-label="Cookie Pairs"
                                :show-legend="false">
                    </bar-chart>
                </b-card>
            </div>
        </section>
    </main>
</template>
<script>
import { mapState } from 'vuex';
import BaseFormatting from '../base-formatting.mjs';
import DateRange from '../date-range';
import BarChart from '../bar-chart';
import ScoreCard from '../score-card';
import {postData} from "@/lib/load_data";

export default {
    name: 'cross-device-matching',
    mixins: [
        BaseFormatting
    ],
    components: {
        BarChart,
        DateRange,
        ScoreCard
    },
    data() {
        return {
            error: null,

            hitsPerDay: [],
            threshold: 25,  // 25%
            cookiePairMatchesPerDay: [],
            cookiePairMatchesPerPerc: []
        }
    },
    computed: {
        ...mapState(['project', 'products', 'start_date', 'end_date']),
        available() {
            return this.products !== null ? this.products.includes('cross-device-matching') : null;
        },
        avgCookiePairs() {
            let sum = 0;
            this.cookiePairMatchesPerPerc.forEach((item) => {
                sum += item.value;
            });
            return sum;
        },
        totalMatchedCookies() {
            let sum = 0;
            this.cookiePairMatchesPerPerc.forEach((item) => {
                sum += item.x >= this.threshold/100 ? item.value : 0;
            });
            return sum;
        },
        totalHits() {
            let sum = 0;
            this.hitsPerDay.forEach((item) => {
                sum += item.value;
            });
            return sum;
        }
    },
    watch: {
        async project() {
            await this.refreshCMD();
        },
        async start_date() {
            await this.refreshCMD();
        },
        async end_date() {
            await this.refreshCMD();
        }
    },
    async mounted() {
        await this.refreshCMD();
    },
    methods: {
        async refreshCMD() {
            if (this.project) {
                [ this.cookiePairMatchesPerDay, this.cookiePairMatchesPerPerc, this.hitsPerDay ] = await Promise.all([
                postData('/api/analysis/cross-device-matching/matches/day', {
                    project: this.project.name,
                    start_date: this.start_date,
                    end_date: this.end_date
                }),
                    postData('/api/analysis/cross-device-matching/matches/perc', {
                    project: this.project.name,
                    start_date: this.start_date,
                    end_date: this.end_date
                }),
                postData('/api/analysis/cross-device-matching/hits', {
                    project: this.project.name,
                    start_date: this.start_date,
                    end_date: this.end_date
                })]);
            }
        },
        hitsColor() {
            if (this.hitsPerDay.length > 0 && this.totalHits > this.hitsPerDay[0].max_total) {
                return 'red';
            }
            return 'green';
        },

        formatColor(x) {
            return x >= this.threshold/100 ? '#45d62e' : '#909090';
        },
        redrawChart() {
            /* Redraw the named barchart 'draw-echart-' + barchart name */
            window.dispatchEvent(new CustomEvent('draw-echart-cross-device-matching-day'));
            window.dispatchEvent(new CustomEvent('draw-echart-cross-device-matching-perc'));
            window.dispatchEvent(new CustomEvent('draw-echart-cross-device-matching-hits'));
        }
    }
}
</script>