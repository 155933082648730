<template>
    <div :id="id" :style="style"></div>
</template>
<script>
import { init } from 'echarts';

export default {
    name: 'donut',
    props: {
        data: {
            type: Array,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        width: {
            type: [String,Number],
            required: false,
            default: '100%'
        },
        height: {
            type: [String,Number],
            required: false,
            default: 400
        }
    },
    data() {
        return {
            chart: null
        }
    },
    watch: {
        data() {
            this.drawChart();
        }
    },
    computed: {
        id() {
            return 'echart-' + this.name.toLowerCase().replace(/ /g, '_');
        },
        style() {
            return "width: " + (typeof this.width === 'number' ? this.width + 'px' : this.width) +
                '; height: ' + (typeof this.height === 'number' ? this.height + 'px' : this.height) + ';'
        }
    },
    mounted() {
        this.drawChart();
    },
    methods: {
        drawChart() {
            if (this.chart === null) {
                this.chart = init(document.getElementById(this.id), null, {
                    renderer: 'svg'
                });
                window.addEventListener('resize', () => {
                    this.chart.resize();
                });
            }

            const legends = [];
            for (let i = 0; i < this.data.length; i++) {
                legends.push(this.data[i].name);
            }

            this.chart.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                // legend: {
                //     orient: 'vertical',
                //     left: 10,
                //     data: legends
                // },
                series: [
                    {
                        name: this.name,
                        type: 'pie',
                        radius: ['30%', '60%'],
                        avoidLabelOverlap: false,
                        label: {
                            position: 'outer',
                            alignTo: 'none',
                            bleedMargin: 5
                        },
                        labelLine: {
                            //show: false
                        },
                        data: this.data
                    }
                ]
            });
        }
    }
}
</script>