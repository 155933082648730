var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('analysis-header',{attrs:{"title":"Ad Analytics"}}),_c('section',{staticClass:"container-fluid my-3"},[(_vm.error)?_c('b-alert',{attrs:{"show":true,"variant":"danger"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),(_vm.available === false)?_c('b-alert',{attrs:{"variant":"danger","show":true}},[_vm._v("Not available for project "),_c('strong',[_vm._v(_vm._s(_vm.project ? _vm.project.name : null))]),_vm._v(".")]):(_vm.available)?_c('div',{staticClass:"ad-analytics-grid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-card-title',[_c('span',[_vm._v("Ad Effect Time-out")]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"bx bxs-help-circle",attrs:{"title":"Time-out of a ad is the maximum time that a conversion can be expected after a touchpoint"}})]),_c('b-table',{staticClass:"mt-3",attrs:{"hover":"","bordered":"","items":_vm.timeouts,"fields":[
                        { key: 'channel', label: 'Channel', sortable: true },
                        { key: 'effect', label: 'Time-out effect', tdClass: 'text-right', thClass: 'text-right', sortable: true },
                    ]},scopedSlots:_vm._u([{key:"cell(effect)",fn:function(data){return [_vm._v(" "+_vm._s(data.value + ' days')+" ")]}}])})],1)]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-card-title',[_c('span',[_vm._v("Overlap")]),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"bx bxs-help-circle",attrs:{"title":"Overlap means the percentage of journeys in which the chosen marketing channel was in the same journey as the reported marketing channel. Interaction means the amount of impact of the chosen marketing channel on the reported marketing channel."}})]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-3"},[_c('h3',[_vm._v("Choose a Channel:")]),_c('b-form-radio-group',{attrs:{"options":_vm.channels,"buttons":"","stacked":"","name":"channel","button-variant":"outline-primary"},model:{value:(_vm.channel),callback:function ($$v) {_vm.channel=$$v},expression:"channel"}})],1),_c('div',{staticClass:"col-9"},[_c('h3',[_vm._v("Overlap of "),_c('strong',{staticClass:"green"},[_vm._v(_vm._s(_vm.channel))]),_vm._v(" with:")]),_c('b-table',{attrs:{"bordered":"","items":_vm.overlap,"fields":[
                                { key: 'channel', label: 'Channel', sortable: true },
                                { key: 'journeys', label: 'Journeys', tdClass: 'text-right', thClass: 'text-right', sortable: true },
                                { key: 'overlap', label: 'Overlap', tdClass: 'text-right', thClass: 'text-right' }
                            ]},scopedSlots:_vm._u([{key:"cell(journeys)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatNumber(data.value))+" ")]}},{key:"cell(overlap)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.total > 0 ? _vm.formatPercentage(data.item.journeys / data.item.total) : 'N/A')+" ")]}}])})],1)])],1)])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }