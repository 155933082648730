<template>
    <main>
        <div class="analysis-nav">
            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <h1>Management &middot; <span class="green">Workflows</span></h1>
                    <div class="d-flex align-items-center" v-if="available">
                        <b-dropdown :html="'<strong>Job:</strong> ' + job" variant="primary" menu-class="dropdown80" :no-flip="true">
                            <b-dropdown-item v-for="j in jobsOverview" @click="selectJob(j.job)" :key="'j-' + j.job" :active="job === j.job">
                                {{ j.job }}
                            </b-dropdown-item>
                        </b-dropdown>

                        <b-dropdown :html="'<strong>Branch:</strong> ' + branch" variant="primary" class="mx-3" menu-class="dropdown80" :no-flip="true">
                            <b-dropdown-item v-for="b in taskBranches" @click="selectBranch(b)" :key="'b-' + b" :active="branch === b">
                                {{ b }}
                            </b-dropdown-item>
                        </b-dropdown>
                        <month-selector v-model="date" v-if="available"></month-selector>
                    </div>
                </div>
            </div>
        </div>

        <section class="container-fluid">
            <b-alert v-if="error !== null" variant="danger" :show="true">{{ error }}</b-alert>
            <b-alert v-if="available === false" variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null}}</strong>.</b-alert>

            <div v-else-if="available && tasksOverview && tasksOverview.length > 0" class="transfer-task-overview-grid">
                <div class="card" style="grid-column: 1; grid-row: 1">
                    <div class="card-body">
                        <h4 class="card-title">
                            <span>Job <span class="green">{{ job }}</span> &middot; Task runs per day</span>
                            <i class="bx bxs-help-circle" v-b-tooltip title="Trend of the amount of tasks that were executed per day"></i>
                        </h4>

                        <bar-chart v-if="taskCountsPerDay && taskCountsPerDay.length > 0" :data="taskCountsPerDay" name="Task counts per day" :height="200" :show-legend="false" series-label="Task runs"/>
                    </div>
                </div>

                <div class="card tasks-overview-table">
                    <div class="card-body">
                        <h4 class="card-title">
                            <span>Job <span class="green">{{ job }}</span> &middot; Task overview</span>
                            <i class="bx bxs-help-circle" v-b-tooltip title="Tasks statuses per task of the selected job of the selected month"></i>
                        </h4>

                        <b-table id="tasks-overview-table" bordered class="mt-3" :fixed="true" :items="tasksOverview" :per-page="pageSize" :current-page="currentPage" :busy="busy" :responsive="true" :fields="[
                            { key: 'task_id', label: 'Task', class: 'text-truncate', sortable: true },
                            { key: 'queued', label: 'Queued', class: 'text-right', sortable: true },
                            { key: 'running', label: 'Running', class: 'text-right', sortable: true },
                            { key: 'up_for_retry', label: 'Up For Retry', class: 'text-right', sortable: true },
                            { key: 'failed', label: 'Failed', class: 'text-right', sortable: true },
                            { key: 'success', label: 'Success', class: 'text-right', sortable: true },
                            { key: 'avg_duration', label: 'Avg. Duration', class: 'text-right' },
                            { key: 'sum_duration', label: 'Total Duration', class: 'text-right' }
                        ]">
                            <template v-slot:table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                </div>
                            </template>
                            <template v-slot:table-colgroup>
                                <col style="width: 35%">
                                <col style="width: 9%">
                                <col style="width: 9%">
                                <col style="width: 9%">
                                <col style="width: 9%">
                                <col style="width: 9%">
                                <col style="width: 10%">
                                <col style="width: 10%">
                            </template>
                            <template v-slot:cell(task_id)="data">
                                <div class="d-flex justify-content-between">
                                    <router-link :to="'/management/transfer/tasks/' + job + '/' + data.item.task_id" custom v-slot="{ href, navigate }">
                                        <a :href="href" :title="data.item.task_id" @click="navigate">{{ data.item.task_id }}</a>
                                    </router-link>
                                    <a href="#" v-b-tooltip.right.html="`Test <strong>${data.item.task_id}</strong> on branch <strong>${branch}</strong>`" @click="airflowConfirm(data.item.task_id)" class="btn btn-primary btn-sm"><i class="bx bx-list-ulplay"></i></a>
                                </div>
                            </template>
                            <template v-slot:cell(avg_duration)="data">{{ formatDuration(data.item.sum_duration/(data.item.queued + data.item.running + data.item.up_for_retry + data.item.failed + data.item.success)) }}</template>
                            <template v-slot:cell(sum_duration)="data">{{ formatDuration(data.item.sum_duration) }}</template>
                        </b-table>
                        <b-pagination v-if="tasksOverview.length > pageSize"
                                      v-model="currentPage"
                                      :total-rows="tasksOverview.length"
                                      :per-page="pageSize"
                                      aria-controls="jobs-overview-table"
                        ></b-pagination>
                    </div>
                </div>
            </div>

            <b-modal v-model="showAirflowTestConfirmModal" title="Are you sure?" size="md" :ok-title="'Execute'" @ok="airflowTest(task)">
                <p>Are you sure you want to test job <strong class="green">{{ job }}</strong> / task <strong class="green">{{ task }}</strong> on branch <strong>{{ branch }}</strong>?</p>
            </b-modal>

            <b-modal v-model="showAirflowTestModal" size="xl" :scrollable="true" :ok-only="true" ok-title="Close">
                <template #modal-header="{ close }">
                    <h5>
                        Execution output of job <strong class="green">{{ job }}</strong> / task <strong class="green">{{ task }}</strong> on branch <strong>{{ branch }}</strong>
                        <b-spinner v-if="executing" label="Executing..."></b-spinner>
                    </h5>

                    <button type="button" aria-label="Close" class="close" @click="close()">×</button>
                </template>
                <pre id="execution-output"></pre>
            </b-modal>
        </section>
    </main>
</template>
<script>
import MonthSelector from '../../month-selector';
import BarChart from '../../bar-chart';
import {postData} from '@/lib/load_data';
import {formatDate} from "@/lib/formatting";
import {mapFields} from "vuex-map-fields";
import BaseFormatting from "@/components/base-formatting.mjs";
import { ENDPOINT_URL } from '@/config';
import { state } from "@/state";
import Ansi2Html from 'ansi-to-html';

const ansi2html = new Ansi2Html();

export default {
    name: 'transfer-tasks-overview',
    mixins: [
        BaseFormatting
    ],
    components: {
        BarChart,
        MonthSelector
    },
    data() {
        return {
            job: null,
            task: null,
            branch: null,
            taskCountsPerDay: [],
            jobsOverview: [],
            tasksOverview: [],
            currentPage: 1,
            taskBranches: [],
            pageSize: 20,
            busy: false,
            error: null,

            showAirflowTestConfirmModal: false,
            showAirflowTestModal: false,
            executing: false
        }
    },
    async mounted() {
        this.job = this.$route.params.jobName;
        await Promise.all([
            this.loadJobs(),
            this.loadTaskNamesPerJob(),
            this.loadTaskBranches()
        ]);
    },
    watch: {
        async project() {
            await Promise.all([
                this.loadJobs(),
                this.loadTaskNamesPerJob(),
                this.loadTaskBranches()
            ]);
        },
        async date() {
            await Promise.all([
                this.loadTaskCountsPerDay(),
                this.loadTaskNamesPerJob()
            ]);
        },
        async job() {
            await Promise.all([
                this.loadTaskCountsPerDay(),
                this.loadTaskNamesPerJob()
            ]);
        }
    },
    computed: {
        ...mapFields(['project','date','products']),
        available() {
            return this.products !== null ? this.products.includes('transfer') : null;
        }
    },
    methods: {
        async loadJobs() {
            this.error = null;
            this.jobsOverview = await postData('/api/transfer/jobs/overview', {
                project: this.project.name,
                date: formatDate(this.date, 'YYYY-MM-DD')
            });
        },
        async loadTaskNamesPerJob() {
            this.error = null;
            this.tasksOverview = await postData('/api/transfer/tasks/overview', {
                project: this.project.name,
                job: this.job,
                date: formatDate(this.date, 'YYYY-MM-DD')
            });
            if (this.tasksOverview.length === 0) {
                this.error = 'There are no task runs for this time period.';
            }
        },
        async loadTaskCountsPerDay() {
            this.error = null;
            this.taskCountsPerDay = await postData('/api/transfer/tasks/counts', {
                project: this.project.name,
                job: this.job,
                date: formatDate(this.date, 'YYYY-MM-DD')
            });
            let count = 0;
            for (let i = 0; this.taskCountsPerDay && i < this.taskCountsPerDay.length; i++) {
                count += this.taskCountsPerDay[i].value;
            }
            if (count === 0) {
                this.error = 'There are no task runs for this time period.';
                this.taskCountsPerDay = [];
            }
        },
        async loadTaskBranches() {
            this.error = null;
            this.taskBranches = await postData('/api/transfer/tasks/branches', {
                project: this.project.name
            });
            if (!this.branch) {
                if (this.taskBranches.indexOf('main') >= 0) {
                    this.branch = 'main';
                }
                if (this.taskBranches.indexOf('master') >= 0) {
                    this.branch = 'master';
                }
            }
        },
        selectJob(job) {
            this.job = job;
            this.$router.push('/management/transfer/tasks/' + job);
        },
        selectBranch(branch) {
            this.branch = branch;
        },
        airflowConfirm(taskid) {
            this.task = taskid;
            this.showAirflowTestConfirmModal = true;
        },
        async airflowTest(taskid) {
            this.showAirflowTestModal = true;
            this.executing = true;
            this.$nextTick(() => {
                const output = document.getElementById('execution-output');
                /* EventSource does not allow additional HTTP request headers to be set, add token to QS */
                const qs = new URLSearchParams();
                qs.set('token', state.token);
                if (this.branch) {
                    qs.set('branch', this.branch)
                }
                const source = new EventSource(ENDPOINT_URL + '/api/transfer/airflow_test/' + this.project.name + '/' + this.job + '/' + taskid + '?' + qs.toString());
                source.addEventListener('message', message => {
                    const json = JSON.parse(message.data);
                    if (json.stdout && json.stdout.trim().length > 0) {
                        output.innerHTML += `<span class="stdout">${ansi2html.toHtml(json.stdout)}</span>`;
                    } else if (json.stderr && json.stderr.trim().length > 0) {
                        output.innerHTML += `<span class="stderr">${ansi2html.toHtml(json.stderr)}</span>`;
                    }
                    /* Scroll to bottom */
                    output.parentNode.scrollTop = output.parentNode.scrollHeight;
                    // console.log(message);
                });
                source.addEventListener('close', (/* message */) => {
                    // console.log(message);
                    this.executing = false;
                    source.close();
                });
                source.addEventListener('error', (error) => {
                    // console.log(error);
                    source.close();
                    this.executing = false;
                    alert(JSON.stringify(error));
                });
            });
        }
    }
}
</script>