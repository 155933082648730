<template>
    <main>
        <div class="analysis-nav d-flex justify-content-between align-items-center">
            <h1>Management &middot; <span class="green">Consent 2.2</span> <span v-if="siteId" class="text-muted">{{ siteId }}</span></h1>
            <b-navbar-nav v-if="available && siteIds" class="mx-3 flex-shrink-0">
                <b-nav-item-dropdown :text="'Site ID: ' + siteId">
                    <button role="menuitem" class="dropdown-item" v-b-modal.add-site-id-modal>
                        <strong><i class="bx bx-plus"></i> Add a new Site ID</strong>
                    </button>
                    <b-dropdown-item v-for="s in siteIds" @click="siteId = s" :key="s" :active="siteId === s">{{ s }}</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <div v-if="available" class="buttonbar">
                <button type="button" class="btn btn-primary" @click="saveConfiguration()">
                    <i class="bx bxs-save"></i>
                    Save
                </button>
                <a :class="{ 'btn': true, 'btn-primary': true, 'disabled': cmpVersion === 0 }" :href="configUrl" download target="_blank">
                    <i class="bx bxs-download"></i>
                    Download Active
                </a>
                <button type="button" class="btn btn-primary" @click="previewConfiguration()" :disabled="cmpVersion === 0">
                    <i class="bx bx-search"></i>
                    Preview
                </button>
                <button type="button" class="btn btn-danger" v-b-modal.publish-modal :disabled="cmpVersion === 0">
                    <i class="bx bx-cloud-upload"></i>
                    Publish
                </button>
            </div>
        </div>
        <section v-if="error" class="container-fluid">
            <b-alert :show="true" variant="danger">{{ error }}</b-alert>
        </section>
        <section v-if="available === false" class="container-fluid">
            <b-alert variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null}}</strong>.</b-alert>
        </section>

        <b-tabs v-else-if="available" class="container-fluid my-3">
            <b-tab title="Settings" lazy>
                <ConsentSettings :gvl="gvl" />
            </b-tab>
            <b-tab title="Stacks" lazy>
                <ConsentStacks :gvl="gvl" />
            </b-tab>
            <b-tab title="Vendors" lazy v-if="gvl !== null">
                <ConsentVendors title="Vendors" :gvl="gvl" :custom-purposes="customPurposes" :vendors="gvl.vendors" subtitle="Select all vendors that process personal data on your website:" />
            </b-tab>
            <b-tab title="Custom Purposes" lazy>
                <ConsentCustomPurposes :custom-purposes="customPurposes" :custom-vendors="customVendors" />
            </b-tab>
            <b-tab title="Custom Vendors" lazy>
                <ConsentVendors title="Custom Vendors" :gvl="gvl" :custom-purposes="customPurposes" :vendors="customVendors" :custom="true" subtitle="Select all custom vendors that process personal data on your website. Custom vendor not included in this list? Contact us to add it." />
            </b-tab>
            <b-tab title="Content" lazy>
                <ConsentContent />
            </b-tab>
        </b-tabs>

        <b-modal id="publish-modal" title="Publish configuration" ok-title="Yes" cancel-title="No" @ok="publishConfiguration">
            <p>Are you sure you want to publish the currently saved configuration?</p>
        </b-modal>

        <b-modal id="add-site-id-modal" title="Add new Site ID" ok-title="Add" cancel-title="Cancel" @ok="addSiteId">
            <p>Add a new Site ID if your project supports multiple different domains.</p>
            <text-input name="newSiteId" v-model="newSiteId" rules="required" placeholder="New Site ID name" />
        </b-modal>
    </main>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import ConsentSettings from './consent-settings';
import ConsentStacks from './consent-stacks';
import ConsentVendors from './consent-vendors';
import ConsentCustomPurposes from './consent-custom-purposes';
import ConsentContent from './consent-content';
import TextInput from '../../text-input';
import sha1 from '@/lib/sha1.mjs';
import { postData, getData } from '@/lib/load_data';
import { getPayload } from '@/state';
import { ENDPOINT_URL } from '@/config';
import {
    getSiteIds,
    getCustomVendors,
    getCustomPurposes,
    getGVL,
} from '@/lib/cmp_data';

export default {
    name: 'consent',
    components: {
        ConsentSettings,
        ConsentStacks,
        ConsentVendors,
        ConsentCustomPurposes,
        ConsentContent,
        TextInput
    },
    data() {
        return {
            error: null,
            message: null,

            gvl: null,
            siteIds: ['default'],
            customPurposes: {},
            customVendors: {},

            newSiteId: null
        };
    },
    watch: {
        async project(value) {
            this.siteIds = await getSiteIds(this.project);
            this.siteId = this.siteIds.length > 0 ? this.siteIds[0] : 'default';

            await this.loadConfiguration(value, this.siteId);
            this.customPurposes = await getCustomPurposes(value, this.siteId);
            this.customVendors = await getCustomVendors(value, this.siteId);
        },
        async siteId(value) {
            await this.loadConfiguration(this.project, value);
            this.customPurposes = await getCustomPurposes(this.project, value);
            this.customVendors = await getCustomVendors(this.project, value);
        }
    },
    computed: {
        ...mapFields(['project', 'siteId', 'products', 'cmpVersion', 'token']),
        available() {
            return this.products !== null ? this.products.includes('consent') : null;
        },
        configUrl() {
            return this.project ? 'https://cdn.onesecondbefore.com/cmp/' + sha1(this.project.name + '-' + this.siteId).substring(0,8) + '.json?v=' + this.cmpVersion : null;
        }
    },
    async mounted() {
        this.gvl = await getGVL();

        if (this.project) {
            this.siteIds = await getSiteIds(this.project);
            this.siteId = this.siteIds.length > 0 ? this.siteIds[0] : 'default';
        }
    },
    methods: {
        async loadConfiguration(project, siteId) {
            const cmpSettings = await getData('/api/cmp/settings/' + project.name + '/' + siteId);
            if (cmpSettings !== null) {
                this.$store.commit('cmpSettings', cmpSettings);
            }
        },
        async saveConfiguration() {
            if (this.cmpVersion === 0) {
                this.$store.commit('incrementCmpVersion');
            }

            const json = await postData('/api/cmp/settings/' + this.project.name + '/' + this.siteId, getPayload(this.project));
            if (json.success) {
                this.$bvToast.toast('Successfully saved CMP config.', {
                    title: 'Consent',
                    autoHideDelay: 5000,
                    solid: true
                });
            } else {
                this.$bvToast.toast(json.message, {
                    title: 'Consent',
                    variant: 'danger',
                    solid: true
                });
            }
        },
        async publishConfiguration() {
            this.$store.commit('incrementCmpVersion');

            const json = await postData('/api/cmp/settings/publish/' + this.project.name + '/' + this.siteId, getPayload(this.project));
            if (json.success) {
                this.$bvToast.toast('Successfully published CMP config. Please wait for the cache to expire.', {
                    title: 'Consent',
                    autoHideDelay: 5000,
                    solid: true
                });
            } else {
                this.$bvToast.toast(json.message, {
                    title: 'Consent',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async previewConfiguration() {
            const json = await postData('/api/cmp/settings/publish/' + this.project.name + '/' + this.siteId + '?preview=true', getPayload(this.project));
            if (json.success) {
                window.open(ENDPOINT_URL + '/cmp/preview/' + this.project.name + '/' + this.siteId + '?v=' + this.cmpVersion + '&token=' + this.token, '_cmp_preview');
            } else {
                this.$bvToast.toast(json.message, {
                    title: 'Consent',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async addSiteId() {
            this.siteId = this.newSiteId;
            if (! this.siteIds.includes(this.newSiteId)) {
                this.siteIds.push(this.newSiteId);
            }
        }
    }
}
</script>