<template>
    <section>
        <h2>Custom Purposes</h2>
        <p>Choose any custom non-IAB purposes that you and/or any custom vendors use.</p>

        <b-alert v-if="error !== null" variant="danger" :show="true" dismissible>{{ error }}</b-alert>

        <dl v-if="customPurposes">
            <b-form-checkbox-group name="customPurposes" v-model="selectedCustomPurposes">
                <div v-for="cp in customPurposes" :key="'cp-' + cp.id">
                    <dt>
                        <b-form-checkbox :value="cp.id" :id="'cp-' + cp.id">[{{ cp.id }}] {{ cp.name}}</b-form-checkbox>
                    </dt>
                    <dd class="indent-4">{{ cp.description }}</dd>
                </div>
            </b-form-checkbox-group>
        </dl>
    </section>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import { MIN_CUSTOM_PURPOSE_ID } from '@/config';

function checkPurposes(a, b) {
    /* Check if all custom purpose ids in array b are contained in array a */
    let ok = true;
    b.forEach((b_) => {
        if (b_ >= MIN_CUSTOM_PURPOSE_ID && !a.includes(b_)) {
            ok = false;
        }
    });
    // console.log('Check', JSON.stringify(a), 'and', JSON.stringify(b), ok);
    return ok;
}

export default {
    name: 'consent-custom-purposes',
    props: {
        customPurposes: {
            required: true
        },
        customVendors: {
            required: true
        }
    },
    data() {
        return {
            error: null,
        }
    },
    computed: {
        ...mapFields(['project', 'selectedVendors', 'selectedCustomPurposes'])
    },
    watch: {
        selectedCustomPurposes(customPurposes) {
            /**
             * Check for each selected vendor whether all custom purposes / legitimate interests are still checked.
             * If not, remove the custom vendor.
             */
            this.selectedVendors.forEach((id) => {
                 const customVendor = this.customVendors[id];
                 if (customVendor) {
                     if (! checkPurposes(customPurposes, customVendor.purposes.concat(customVendor.legIntPurposes))) {
                         this.$store.commit('removeCustomVendor', id);
                         this.$bvToast.toast(`Disabled custom vendor '${customVendor.name}' because it depends on an unselected custom purpose.`, {
                             title: 'Consent',
                             autoHideDelay: 5000,
                             solid: true
                         });
                     }
                 }
             })
        }
    }
}
</script>