<template>
    <div class="container">
        <div class="row my-5">
            <div class="col-12 col-sm-10 offset-sm-2">
                <h1>Forgot your password?</h1>
                <p>Enter your email address below to request a new password for <strong>console.onesecondbefore.com</strong>.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-8 offset-sm-2">
                <b-alert variant="success" :show="true" v-if="message !== null">{{ message }}</b-alert>
                <b-alert variant="danger" :show="true" v-if="error !== null" dismissible>
                    <i class="bx bx-error"></i>
                    {{ error }}
                </b-alert>
                <form action="#" method="POST">
                    <text-input name="email" v-model="email" type="email" label="Email address" rules="required"/>
                    <button class="btn btn-lg btn-primary" @click.prevent="sendPasswordRequest">Request new password</button>
                </form>
                <p class="my-3">
                    <router-link to="/login">Back to login</router-link>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import {postDataUnauthorized} from "@/lib/load_data";
import TextInput from '@/components/text-input';

export default {
    name: 'forgot',
    components: {
        TextInput
    },
    data() {
        return {
            email: null,
            message: null,
            error: null,
        }
    },
    methods: {
        async sendPasswordRequest() {
            this.error = null;
            this.message = null;
            const response = await postDataUnauthorized('/api/forgot', { email: this.email });
            if (response.error) {
                this.error = response.error;
            } else {
                this.message = response.message;
            }
        }
    }
}
</script>
