<template>
    <main>
        <div class="analysis-nav">
            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <h1>Usage &middot; <span class="green">Workflows</span></h1>
                    <month-selector v-model="date" v-if="available"></month-selector>
                </div>
            </div>
        </div>
        <section class="container-fluid">
            <b-alert v-if="error !== null" variant="danger" :show="true">{{ error }}</b-alert>
            <b-alert v-if="available === false" variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null}}</strong>.</b-alert>

            <div v-else-if="available" class="transfer-units-grid">
                <score-card title="Units MTD" id="units-mtd" :value="totalUnits" tooltip="Total number of Workflows Units spent for the selected month"></score-card>
                <score-card title="Units estimated" id="units-est" :value="estimatedUnits" tooltip="Estimated number of Workflows Units spent for the entire selected month"></score-card>
                <score-card title="Avg. units per day" id="avg-units" :value="avgUnits" tooltip="Average number of Workflows Units per day for selected month"></score-card>
                <section id="transfer-units-chart">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">
                                <span>Workflows Units</span>
                                <i class="bx bxs-help-circle" v-b-tooltip title="Number of Workflows Units spent per day for the selected month"></i>
                            </h4>

                            <bar-chart :data="data" name="Workflows Units" series-label="Units" :show-legend="false"/>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    </main>
</template>
<script>
import BarChart from '../bar-chart';
import ScoreCard from "../score-card";
import MonthSelector from '../month-selector';
import {postData} from "@/lib/load_data";
import {formatDate} from "@/lib/formatting";
import {mapFields} from "vuex-map-fields";
import BaseFormatting from "@/components/base-formatting.mjs";

export default {
    name: 'transfer-units',
    mixins: [
        BaseFormatting
    ],
    components: {
        ScoreCard,
        BarChart,
        MonthSelector
    },
    data() {
        return {
            error: null,
            data: []
        }
    },
    mounted() {
        this.loadCpu();
    },
    watch: {
        project(value) {
            if (value) {
                this.loadCpu();
            }
        },
        date() {
            this.loadCpu();
        }
    },
    computed: {
        ...mapFields(['project', 'products', 'date']),
        available() {
            return this.products !== null ? this.products.includes('transfer') : null;
        },
        totalUnits() {
            let sum = 0;
            for (let i = 0; i < this.data.length; i++) {
                sum += this.data[i].value;
            }
            return sum;
        },
        estimatedUnits() {
            /* Calculate number of expected hits based upon month-to-date hits and current time rounded to the hour */
            let current = new Date();
            let firstOfMonth = Math.round(new Date(current.getFullYear(), current.getMonth(), 1).getTime() / (3600 * 1000));
            let firstOfNextMonth = Math.round(new Date(current.getFullYear(), current.getMonth() + 1, 1).getTime() / (3600 * 1000));

            /* Previous months selected ? */
            if (Math.round(this.date.getTime() / (3600 * 1000)) >= firstOfMonth) {
                let now = Math.round(Date.now() / (3600 * 1000));
                if (now - firstOfMonth >= 24) {
                    /* Current month should have at least one day worth of data */
                    return Math.round(this.totalUnits * (firstOfNextMonth - firstOfMonth) / (now - firstOfMonth));
                } else {
                    return null;
                }
            } else {
                return this.totalUnits;
            }
        },
        avgUnits() {
            let sum = 0.0;
            let count = 0;
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].value > 0) {
                    count++;
                    sum += this.data[i].value;
                }
            }
            return count > 0 ? Math.round(sum / count) : null;
        }
    },
    methods: {
        async loadCpu() {
            this.data = await postData('/api/transfer/cpu', {
                project: this.project.name,
                date: formatDate(this.date, 'YYYY-MM-DD')
            });
        }
    }
}
</script>