import VueRouter from 'vue-router';
import { state } from './state';

import AcceptInvitation from '@/components/accept-invitation';
import ChangePassword from '@/components/change-password';
import Forgot from '@/components/forgot';
import Homepage from '@/components/homepage';
import Login from '@/components/login';
import Logout from '@/components/logout';
import Welcome from '@/components/welcome';

import Analysis from '@/components/analysis';
// import ConsentAnalytics from '@/components/analysis/consent';
import JourneyAnalytics from '@/components/analysis/journey-analytics';
import AdAnalytics from '@/components/analysis/ad-analytics';
import Results from '@/components/analysis/results';
import BuyingModeDetection from '@/components/analysis/buying-mode-detection';
import CrossDeviceMatching from '@/components/analysis/cross-device-matching';
import DataDrivenMicroAttribution from '@/components/analysis/data-driven-micro-attribution';

import Management from '@/components/management';
import Consent22 from "@/components/management/consent-22";
import TransferJobsOverview from '@/components/management/transfer/transfer-jobs-overview';
import TransferTasksOverview from '@/components/management/transfer/transfer-tasks-overview';
import TransferTaskList from '@/components/management/transfer/transfer-tasks-list';

import Usage from '@/components/usage';
import CaptureHits from '@/components/usage/capture-hits';
import TransferUnits from '@/components/usage/transfer-units';

import Capture from '@/components/management/capture';

import MediaPlannerForm from "@/components/media-planner/media-planner-form";

import HexDashboard from '@/components/hex/dashboard'


const routes = [
    {path: '/', component: Welcome},
    {path: '/homepage', component: Homepage},
    {path: '/login', component: Login},
    {path: '/logout', component: Logout},
    {path: '/forgot', component: Forgot},
    {path: '/change-password/:token', component: ChangePassword},
    {path: '/accept-invitation/:token', component: AcceptInvitation},
    {path: '/welcome', component: Welcome},
    {
        path: '/analysis',
        component: Analysis,
        children: [{
            path: 'ad-analytics',
            component: AdAnalytics
        }, {
            path: 'buying-mode-detection',
            component: BuyingModeDetection
        }, {
            path: 'cross-device-matching',
            component: CrossDeviceMatching
        }, {
            path: 'data-driven-micro-attribution/:channel',
            component: DataDrivenMicroAttribution
        }, {
            path: 'data-driven-micro-attribution',
            component: DataDrivenMicroAttribution
        }, {
            path: 'journey-analytics',
            component: JourneyAnalytics
        }, {
            path: 'results',
            component: Results
        }]
    }, {
        path: '/dashboard/hex',
        component: HexDashboard
    }, {
        path: '/dashboard/:productName/:category',
        component: HexDashboard
    }, {
        path: '/management',
        component: Management,
        children: [{
            path: 'transfer/jobs',
            component: TransferJobsOverview
        }, {
            path: 'transfer/tasks/:jobName',
            component: TransferTasksOverview
        }, {
            path: 'transfer/tasks/:jobName/:taskName',
            component: TransferTaskList
        }, {
            path: 'capture',
            component: Capture
        }, {
            path: 'consent',
            component: Consent22
        }]
    }, {
        path: '/media-planner',
        component: MediaPlannerForm
    }, {
        path: '/usage',
        component: Usage,
        children: [{
            path: 'capture',
            component: CaptureHits
        }, {
            path: 'transfer',
            component: TransferUnits
        }]
    }
];

const router = new VueRouter({
    linkActiveClass: 'active',
    routes
});

let targetUrl = null;

router.beforeEach((to, from, next) => {
    if (state.token !== null) {
        if (targetUrl !== null) {
            const url = targetUrl;
            targetUrl = null;
            return next(url);
        } else if (to.path === '/') {
            return next('/homepage');
        } else {
            /* All good */
            return next();
        }
    } else if (to.path !== '/' && to.path !== '/login' && to.path !== '/forgot' && to.path !== '/welcome' && to.path !== '/homepage' && to.path !== '/logout'
        && to.path.indexOf('/change-password') !== 0 && to.path.indexOf('/accept-invitation') !== 0) {
        targetUrl = to.path;
        next('/login');
    } else {
        next();
    }
});

export default router;