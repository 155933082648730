import { GVL } from "@iabtechlabtcf/core";
import { getData } from "@/lib/load_data";

const GVL_URL = 'https://cdn.onesecondbefore.com/cmp/vendor-list.json';

let gvl = null;
let siteIds = null;
let customPurposes = null;
let customVendors = null;
let currentProject = null;
let currentSiteId = null;

export async function getGVL() {
    if (gvl === null) {
        const json = await getData(GVL_URL);
        gvl = new GVL(json);
    }
    return gvl;
}

export async function getSiteIds(project) {
    if (currentProject === null || currentProject !== project) {
        currentProject = project;
        siteIds = await getData('/api/cmp/site-ids/' + project.name);
    }
    return siteIds;
}

export async function getCustomPurposes(project, siteId) {
    if (customPurposes === null || (currentProject !== null && currentProject !== project) || (currentSiteId !== null && currentSiteId !== siteId)) {
        currentProject = project;
        currentSiteId = siteId;
        customPurposes = await getData('/api/cmp/custom-purposes/' + project.name + '/' + siteId + '?lang=en');
    }
    return customPurposes;
}

export async function getCustomVendors(project, siteId) {
    if (customVendors === null || (currentProject !== null && currentProject !== project)|| (currentSiteId !== null && currentSiteId !== siteId)) {
        currentProject = project;
        currentSiteId = siteId;
        customVendors = await getData('/api/cmp/custom-vendors/' + project.name + '/' + siteId);
    }
    return customVendors;
}
