<template>
    <b-modal v-if="vendor !== null && gvl !== null" id="modal-publisher-restrictions" :title="'Publisher restrictions for ' + vendor.name + ' (' + vendor.id + ')'" ok-title="Apply" size="xl" @ok="savePublisherRestriction">
        <div class="row">
            <div class="col-md-7">
                <p>Select the publisher restrictions you would like to apply to this vendor:</p>
                <table class="table">
                    <thead v-if="Object.keys(vendor.purposes).length > 0">
                        <tr>
                            <th colspan="2">Purposes</th>
                        </tr>
                    </thead>
                    <tbody v-if="Object.keys(vendor.purposes).length > 0">
                        <tr v-for="p in vendor.purposes" :key="'p-' + p">
                            <td class="align-middle">{{ purposeName(p) }}</td>
                            <td>
                                <div v-if="p === 1" class="my-2">No Restriction</div>
                                <b-form-select v-else class="form-control" name="restriction" v-model="vendorRestriction[p]" :options="getPublisherRestrictionsForPurpose(vendor, p)"></b-form-select>
                            </td>
                        </tr>
                    </tbody>
                    <thead v-if="Object.keys(vendor.legIntPurposes).length > 0">
                        <tr>
                            <th colspan="2">Legitimate Interests</th>
                        </tr>
                    </thead>
                    <tbody v-if="Object.keys(vendor.legIntPurposes).length > 0">
                    <tr v-for="p in vendor.legIntPurposes" :key="'p-' + p"  >
                        <td class="align-middle">{{ purposeName(p) }}</td>
                        <td>
                            <b-form-select class="form-control" name="restriction" v-model="vendorRestriction[p]" :options="getPublisherRestrictionsForLegitimateInterest(vendor, p)"></b-form-select>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-5 small">
                <p>Choose whether you want to impose further restrictions on purposes requested by a vendor and their legal basis:</p>
                <dl>
                    <dt>No Restriction</dt>
                    <dd>No restrictions apply.</dd>
                    <dt>Not Allowed</dt>
                    <dd>This purpose is not allowed. The vendor is not allowed to process personal data according to this purpose.</dd>
                    <dt>Require Consent</dt>
                    <dd>The vendor has expressed legitimate interest as a legal basis for this purpose <u>and</u> this purpose is flexible. Consent is required, legitimate interest is objected.</dd>
                    <dt>Require Legitimate Interest</dt>
                    <dd>The vendor has expressed consent as a legal basis for this purpose <u>and</u> this purpose is flexible. Legitimate interest should not be objected to, only consent is not sufficient.</dd>
                </dl>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { RestrictionType } from '@iabtechlabtcf/core';

export default {
    name: 'consent-publisher-restrictions',
    props: {
        vendor: {
            required: true
        },
        gvl: {
            required: true
        },
        customPurposes: {
            required: true
        }
    },
    data() {
        return {
            error: null,

            vendorRestriction: {}
        }
    },
    async mounted() {
        let publisherRestrictions = this.publisherRestrictions[this.vendor.id] || {};
        for (let i = 0; i < this.vendor.purposes.length; i++) {
            if (publisherRestrictions[this.vendor.purposes[i]] === undefined) {
                /* Default to No Restriction */
                publisherRestrictions[this.vendor.purposes[i]] = null;
            }
        }
        for (let i = 0; i < this.vendor.legIntPurposes.length; i++) {
            if (publisherRestrictions[this.vendor.legIntPurposes[i]] === undefined) {
                /* Default to No Restriction */
                publisherRestrictions[this.vendor.legIntPurposes[i]] = null;
            }
        }
        this.vendorRestriction = publisherRestrictions;

        this.$bvModal.show('modal-publisher-restrictions');
    },
    computed: {
        ...mapFields(['project', 'publisherRestrictions'])
    },
    methods: {
        purposeName(id) {
            if (this.gvl.purposes && this.gvl.purposes[id]) {
                return this.gvl.purposes[id].name;
            } else if (this.customPurposes && this.customPurposes[id]) {
                return this.customPurposes[id].name;
            } else {
                return '??';
            }
        },
        savePublisherRestriction() {
            this.$store.commit('publisherRestrictions', {
                vendor: this.vendor,
                publisherRestrictions: this.vendorRestriction
            });
        },

        getPublisherRestrictionsForPurpose(vendor, purposeId) {
            const result = [{
                value: null, text: 'No Restriction'
            }, {
                value: RestrictionType.NOT_ALLOWED, text: 'Not Allowed'
            }];

            if (vendor.flexiblePurposes && vendor.flexiblePurposes.includes(purposeId)) {
                result.push({
                    value: RestrictionType.REQUIRE_LI, text: 'Require Legitimate Interest'
                });
            }

            return result;
        },

        getPublisherRestrictionsForLegitimateInterest(vendor, legIntId) {
            const result = [{
                value: null, text: 'No Restriction'
            }, {
                value: RestrictionType.NOT_ALLOWED, text: 'Not Allowed'
            }];

            if (vendor.flexiblePurposes && vendor.flexiblePurposes.includes(legIntId)) {
                result.push({
                    value: RestrictionType.REQUIRE_CONSENT, text: 'Require Consent'
                });
            }

            return result;
        }
    }
}
</script>