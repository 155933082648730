<template>
    <main>
        <analysis-header title="Ad Analytics"></analysis-header>

        <section class="container-fluid my-3">
            <b-alert v-if="error" :show="true" variant="danger">{{ error }}</b-alert>
            <b-alert v-if="available === false" variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null}}</strong>.</b-alert>

            <div v-else-if="available" class="ad-analytics-grid">
                <div class="card">
                    <div class="card-body">
                        <b-card-title>
                            <span>Ad Effect Time-out</span>
                            <i class="bx bxs-help-circle" v-b-tooltip title="Time-out of a ad is the maximum time that a conversion can be expected after a touchpoint"></i>
                        </b-card-title>

                        <b-table hover bordered :items="timeouts" class="mt-3" :fields="[
                            { key: 'channel', label: 'Channel', sortable: true },
                            { key: 'effect', label: 'Time-out effect', tdClass: 'text-right', thClass: 'text-right', sortable: true },
                        ]">
                            <template #cell(effect)="data">
                                {{ data.value + ' days' }}
                            </template>
                        </b-table>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <b-card-title>
                            <span>Overlap</span>
                            <i class="bx bxs-help-circle" v-b-tooltip title="Overlap means the percentage of journeys in which the chosen marketing channel was in the same journey as the reported marketing channel. Interaction means the amount of impact of the chosen marketing channel on the reported marketing channel."></i>
                        </b-card-title>

                        <div class="row mt-3">
                            <div class="col-3">
                                <h3>Choose a Channel:</h3>
                                <b-form-radio-group
                                    v-model="channel"
                                    :options="channels"
                                    buttons
                                    stacked
                                    name="channel"
                                    button-variant="outline-primary"
                                ></b-form-radio-group>
                            </div>
                            <div class="col-9">
                                <h3>Overlap of <strong class="green">{{ channel }}</strong> with:</h3>
                                <b-table bordered :items="overlap" :fields="[
                                    { key: 'channel', label: 'Channel', sortable: true },
                                    { key: 'journeys', label: 'Journeys', tdClass: 'text-right', thClass: 'text-right', sortable: true },
                                    { key: 'overlap', label: 'Overlap', tdClass: 'text-right', thClass: 'text-right' }
                                ]">
                                    <template #cell(journeys)="data">
                                        {{ formatNumber(data.value) }}
                                    </template>
                                    <template #cell(overlap)="data">
                                        {{ data.item.total > 0 ? formatPercentage(data.item.journeys / data.item.total) : 'N/A' }}
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script>
import AnalysisHeader from './analysis-header';
import {postData} from "@/lib/load_data";
import {mapState} from "vuex";
import BaseFormatting from '../base-formatting.mjs';

export default {
    name: 'analysis-ad-analytics',
    components: {
        AnalysisHeader,
    },
    mixins: [
        BaseFormatting
    ],
    data() {
        return {
            error: null,

            channelOverlap: [],
            channel: 'Affiliate',

            channels: [
                {text: 'Affiliate', value: 'Affiliate'},
                {text: 'Display', value: 'Display'},
                {text: 'Email', value: 'Email'},
                {text: 'Push Notifications', value: 'Push Notifications'},
                {text: 'Paid search', value: 'Paid search'},
                {text: 'Referral', value: 'Referral'},
                {text: 'Remarketing', value: 'Remarketing'},
                {text: 'Social', value: 'Social'}
            ],

            timeouts: [{
                channel: 'Affiliate',
                effect: 7
            }, {
                channel: 'Display',
                effect: 4
            }, {
                channel: 'Email',
                effect: 4
            }, {
                channel: 'Push Notifications',
                effect: 3
            }, {
                channel: 'Paid search',
                effect: 10
            }, {
                channel: 'Referral',
                effect: 15
            }, {
                channel: 'Remarketing',
                effect: 1.5
            }, {
                channel: 'Social',
                effect: 4
            }]
        };
    },
    watch: {
        project() {
            this.queryChannelOverlap();
        }
    },
    computed: {
        ...mapState(['project', 'products']),
        available() {
            return this.products !== null ? this.products.includes('analytics') : null;
        },
        overlap() {
            const results = [];
            for (let i = 0; i < this.channelOverlap.length; i++) {
                if (this.channelOverlap[i].marketing_channel === this.channel) {
                    results.push({
                        channel: this.channelOverlap[i].segment,
                        journeys: this.channelOverlap[i].journeys,
                        total: this.channelOverlap[i].total
                    })
                }
            }
            return results;
        }
    },
    mounted() {
        this.queryChannelOverlap();
    },
    methods: {
        async queryChannelOverlap() {
            this.channelOverlap = await postData('/api/analysis/channel-overlap', {
                project: this.project.name
            });
        }
    }
}
</script>