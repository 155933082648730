export default {
    token(state, value) { state.token = value; },
    picture(state, value) { state.picture = value; },
    project(state, value) { state.project = value; },
    date(state, value) { state.date = value; },

    login(state, payload) {
        if (payload.token !== undefined) {
            state.token = payload.token;
        }
        if (payload.picture !== undefined) {
            state.picture = payload.picture;
        }
    },

    logout(state) {
        state.token = null;
        state.permissions = [];
        state.picture = null;
        state.project = null;
    },

    cmpSettings(state, payload) {
        state.cmpVersion = payload.cmpVersion || 0;
        state.publisherCC = payload.publisherCC || 'NL';
        state.consentLanguage = payload.consentLanguage || 'en';
        state.selectedStacks = (payload.stacks || []).sort();
        state.selectedVendors = (payload.vendors || []).sort();
        state.selectedCustomPurposes = (payload.customPurposes || []).sort();
        state.cookieStatementUrl = payload.cookieStatementUrl || null;
        state.publisherRestrictions = payload.publisherRestrictions || {};
        state.cmpTitle = payload.title || null;
        state.font = payload.font || null;
        state.logo = payload.logo || null;
        state.trackerUrl = payload.trackerUrl || 'https://c.onesecondbefore.com';
        state.ttl = payload.ttl || 86400;
        state.consentUrl = payload.consentUrl || null;
        state.introduction = payload.introduction || null;
        state.closeButtonAllowed = payload.closeButtonAllowed !== false; /* Default to true if not specified */
        state.rejectAllTitle = payload.rejectAllTitle || null;
        state.rejectAllText = payload.rejectAllText || '';  /* Specify '' rather than null to update the TipTap instance */
        state.page = payload.page === 0 || payload.page === 1 ? payload.page : 1;
        state.googleConsentMode = payload.googleConsentMode || false;
    },

    incrementCmpVersion(state) {
        state.cmpVersion++;
    },

    addCustomPurpose(state, cpId) {
        if (state.selectedCustomPurposes.includes(cpId)) {
            console.error(`Custom purposes already contains purpose '${cpId}'`);
        } else {
            state.selectedCustomPurposes.push(cpId);
        }
    },

    removeCustomVendor(state, vId) {
        if (state.selectedVendors.includes(vId)) {
            console.log('Remove', vId, 'from', JSON.stringify(state.selectedVendors));
            state.selectedVendors.splice(state.selectedVendors.indexOf(vId), 1);
            console.log('Result', JSON.stringify(state.selectedVendors));
        } else {
            console.error(`Custom vendor '${vId}' not found`);
        }
    },

    publisherRestrictions(state, payload) {
        state.publisherRestrictions = state.publisherRestrictions || {};

        /* Remove all "No Restrictions" (null) from the payload object */
        /* { "2": null, "3": 0 } --> { "3": 0 } */
        let publisherRestrictions = {};
        Object.keys(payload.publisherRestrictions).forEach((key) => {
            if (payload.publisherRestrictions[key] !== null) {
                publisherRestrictions[key] = payload.publisherRestrictions[key];
            }
        });

        /* If no publisher restrictions remain for this vender, remove the entire key, instead of saving "vendor_id":{} */
        if (Object.keys(publisherRestrictions).length === 0) {
            delete state.publisherRestrictions[payload.vendor.id];
        } else {
            state.publisherRestrictions[payload.vendor.id] = publisherRestrictions;
        }
    }
}