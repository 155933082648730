<template>
    <section>
        <div class="row">
            <div class="col-12 col-md-8">
                <h2>Stacks</h2>
                <p>Choose one or multiple stacks that apply to your website and you want the visitor to consent:</p>

                <b-alert v-if="error !== null" variant="danger" :show="true" dismissible>{{ error }}</b-alert>

                <dl v-if="gvl">
                    <b-form-checkbox-group name="stacks" v-model="selectedStacks">
                        <div v-for="s in gvl.stacks" :key="'s-' + s.id">
                            <dt>
                                <b-form-checkbox :value="s.id" :id="'s-' + s.id">[{{ s.id }}] {{ s.name}}</b-form-checkbox>
                            </dt>
                            <dd class="indent-4">
                                <template v-if="gvl.stacks[s.id].purposes && gvl.stacks[s.id].purposes.length > 0">
                                    Purposes:
                                    <ul>
                                        <li v-for="p in gvl.stacks[s.id].purposes" :key="'sp-' + s.id + '-' + p">
                                            <strong>[{{ p }}]</strong> {{ gvl.purposes[p].name }}
                                            <i class="bx bxs-help-circle" v-b-tooltip.html :title="'<strong>Example:</strong><br><p>' + gvl.purposes[p].illustrations[0] + '</p>'"></i>
                                        </li>
                                    </ul>
                                </template>
                                <template v-if="gvl.stacks[s.id].specialFeatures && gvl.stacks[s.id].specialFeatures.length > 0">
                                    Special features:
                                    <ul>
                                        <li v-for="f in gvl.stacks[s.id].specialFeatures" :key="'sf-' + s.id + '-' + f">
                                            <strong>[{{ f }}]</strong> {{ gvl.specialFeatures[f].name }}
                                        </li>
                                    </ul>
                                </template>
                            </dd>
                        </div>
                    </b-form-checkbox-group>
                </dl>
            </div>

            <div class="col-md-4">
                <section class="score-card">
                    <div class="score-card-body">
                        <h4 class="card-title">Stacks</h4>
                        <p>Multiple purposes have been grouped in so-called stacks. This eliminates the need to show a large number of purposes in the first layer of the consent management UI.</p>
                        <p>Make sure to not select stacks with overlapping purposes.</p>
                        <p>Purpose 1 is never part of any stacks, and will always be shown regardless of the chosen stacks.</p>
                        <p>For more information about stacks, consult <a href="https://iabeurope.eu/iab-europe-transparency-consent-framework-policies" target="_blank">iabeurope.eu</a>.</p>
                    </div>
                </section>
            </div>
        </div>
        <div class="row" v-if="gvl">
            <div class="col-12">
                <table class="table table-bordered table-sm stacks-table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th colspan="11">Purposes</th>
                        <th colspan="2">Special<br/>Features</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th>7</th>
                        <th>8</th>
                        <th>9</th>
                        <th>10</th>
                        <th>11</th>
                        <th>1</th>
                        <th>2</th>
                    </tr>
                    </thead>
                    <template v-for="s in gvl.stacks">
                        <tr :key="'s1-' + s.id" :class="{ 'active': selectedStacks.includes(s.id) }">
                            <td><strong>[{{ s.id }}]</strong> {{ s.name }}</td>
                            <td>{{ gvl.stacks[s.id].purposes && gvl.stacks[s.id].purposes.includes(1) ? 'x' : '' }}</td>
                            <td>{{ gvl.stacks[s.id].purposes && gvl.stacks[s.id].purposes.includes(2) ? 'x' : '' }}</td>
                            <td>{{ gvl.stacks[s.id].purposes && gvl.stacks[s.id].purposes.includes(3) ? 'x' : '' }}</td>
                            <td>{{ gvl.stacks[s.id].purposes && gvl.stacks[s.id].purposes.includes(4) ? 'x' : '' }}</td>
                            <td>{{ gvl.stacks[s.id].purposes && gvl.stacks[s.id].purposes.includes(5) ? 'x' : '' }}</td>
                            <td>{{ gvl.stacks[s.id].purposes && gvl.stacks[s.id].purposes.includes(6) ? 'x' : '' }}</td>
                            <td>{{ gvl.stacks[s.id].purposes && gvl.stacks[s.id].purposes.includes(7) ? 'x' : '' }}</td>
                            <td>{{ gvl.stacks[s.id].purposes && gvl.stacks[s.id].purposes.includes(8) ? 'x' : '' }}</td>
                            <td>{{ gvl.stacks[s.id].purposes && gvl.stacks[s.id].purposes.includes(9) ? 'x' : '' }}</td>
                            <td>{{ gvl.stacks[s.id].purposes && gvl.stacks[s.id].purposes.includes(10) ? 'x' : '' }}</td>
                            <td>{{ gvl.stacks[s.id].purposes && gvl.stacks[s.id].purposes.includes(11) ? 'x' : '' }}</td>
                            <td>{{ gvl.stacks[s.id].specialFeatures && gvl.stacks[s.id].specialFeatures.includes(1) ? 'x' : '' }}</td>
                            <td>{{ gvl.stacks[s.id].specialFeatures && gvl.stacks[s.id].specialFeatures.includes(2) ? 'x' : '' }}</td>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </section>
</template>
<script>
import { mapFields } from 'vuex-map-fields';

export default {
    name: 'consent-stacks',
    props: {
        gvl: {
            required: true
        }
    },
    data() {
        return {
            error: null
        }
    },
    computed: {
        ...mapFields(['project', 'selectedStacks'])
    }
}
</script>