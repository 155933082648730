import { formatDate } from './lib/formatting';
import sha1 from "./lib/sha1.mjs";

const now = new Date();

export const state= {
    get token() {
        return window.localStorage.getItem('osb_console_token');
    },
    set token(value) {
        if (value) {
            window.localStorage.setItem('osb_console_token', value);
        } else {
            window.localStorage.removeItem('osb_console_token');
        }
    },
    get email() {
        const t = this.token;
        if (t) {
            const parts = t.split('.');
            if (parts.length === 3) {
                try {
                    return JSON.parse(atob(parts[1])).username;
                } catch (ex) {
                    /* Ignore */
                }
            }
        }
        return null;
    },
    get picture() {
        return window.localStorage.getItem('osb_console_picture');
    },
    set picture(value) {
        if (value) {
            window.localStorage.setItem('osb_console_picture', value);
        } else {
            window.localStorage.removeItem('osb_console_picture');
        }
    },
    permissions: [],
    project: null,
    products: null,
    date: new Date(now.getFullYear(), now.getMonth(), 1),

    start_date: formatDate(new Date(now.getFullYear(), now.getMonth(), 1), 'YYYY-MM-DD'),
    end_date: formatDate(new Date(now.getFullYear(), now.getMonth() + 1, 0), 'YYYY-MM-DD'),
    timebase: 'touchpoint',
    segments: ['successful','unsuccessful'],

    /* Consent */
    siteId: null,
    cmpVersion: 1,
    consentLanguage: 'en',
    publisherCC: 'nl',
    selectedStacks: [],
    selectedVendors: [],
    selectedCustomPurposes: [],
    publisherRestrictions: {},
    cookieStatementUrl: null,
    privacyPolicyUrl: null,
    cmpTitle: 'Manage Your Privacy',
    font: null,
    logo: null,
    page: 1,
    trackerUrl: 'https://c.onesecondbefore.com',
    ttl: 86400,
    introduction: null,
    consentUrl: null,
    closeButtonAllowed: true,
    rejectAllTitle: null,
    rejectAllText: null,
    googleConsentMode: false,
}

export function getPayload(project) {
    return {
        cmpVersion: state.cmpVersion || 1,
        publisherCC: state.publisherCC || 'nl',
        consentLanguage: state.consentLanguage || 'en',
        stacks: state.selectedStacks || [],
        vendors: state.selectedVendors || [],
        customPurposes: state.selectedCustomPurposes || [],
        publisherRestrictions: state.publisherRestrictions || {},
        gvlUrl: 'https://cdn.onesecondbefore.com/cmp/' + sha1(project.name).substring(0,8) + '-vendor-list.json',
        cookieStatementUrl: state.cookieStatementUrl,
        privacyPolicyUrl: state.privacyPolicyUrl,
        title: state.cmpTitle,
        font: state.font,
        logo: state.logo,
        trackerUrl: state.trackerUrl,
        ttl: state.ttl,
        introduction: state.introduction !== '<p></p>' ? state.introduction : null,
        closeButtonAllowed: state.closeButtonAllowed,
        rejectAllTitle: state.rejectAllTitle,
        rejectAllText: state.rejectAllText,
        page: state.page,
        googleConsentMode: state.googleConsentMode || false
    };
}