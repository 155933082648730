<template>
    <ValidationProvider tag="div" mode="lazy" :rules="rules" :name="label || name" v-slot="{ errors }">
        <div :class="{'form-group': true, 'is-invalid': errors[0] }">
            <label v-if="label" class="form-label d-flex align-items-center">
                <span :class="isRequired ? 'label-required' : 'label'" @click="$refs.input.focus()">{{ label }}</span>
                <i v-if="tooltip" class="bx bxs-help-circle ml-2" v-b-tooltip.hover.click :title="tooltip"></i>
            </label>
            <div :class="prepend || append ? 'input-group' : ''">
                <div v-if="prepend" class="input-group-prepend">
                    <span class="input-group-text">{{ prepend }}</span>
                </div>
                <input :type="type"
                    class="form-control"
                    :name="name"
                    v-model="currentValue"
                    ref="input"
                    v-bind="$attrs"
                    v-on="filterListeners"
                    :autocomplete="autocomplete"
                    :data-1p-ignore="autocomplete === 'off'"
                    @keydown="keydown"
                />
                <div v-if="append" class="input-group-append">
                    <span class="input-group-text">{{ append }}</span>
                </div>
            </div>
            <slot name="explanation"></slot>
            <span v-if="errors[0]" class="invalid-feedback">{{ errors[0] }}</span>
        </div>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'TextInput',
    inheritAttrs: false,
    components: {
        ValidationProvider
    },
    props: {
        value: {
            default: ''
        },
        rules: {
            type: [String, Object],
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            required: false
        },
        type: {
            type: String,
            default: 'text'
        },
        tooltip: {
            type: String,
            required: false
        },
        mask: {
            type: String,
            required: false
        },
        prepend: {
            type: String
        },
        append: {
            type: String
        },
        transform: {
            type: String
        },
        explanation: {
            type: String
        },
        autocomplete: {
            type: String,
            required: false
        }
    },
    data () {
        return {
            currentValue: ''
        }
    },
    mounted() {
        if (typeof this.value !== 'undefined') {
            this.currentValue = this.value;
        }
    },
    computed: {
        isRequired() {
            if (typeof this.rules === 'string') {
                return this.rules.split('|').find((el) => el === 'required') !== undefined;
            } else if (this.rules['required'] !== undefined) {
                return this.rules['required'];
            } else {
                // console.warn('Unknown type for rules');
                return false;
            }
        },
        filterListeners() {
            let listeners = {};
            for (let l in this.$listeners) {
                if (l !== 'input') {
                    listeners[l] = this.$listeners[l];
                }
            }
            return listeners;
        }
    },
    watch: {
        currentValue (val) {
            if (typeof val === 'string') {
                if (this.transform === 'uppercase') {
                    val = val.toUpperCase();
                } else if (this.transform === 'lowercase') {
                    val = val.toLowerCase();
                }
            }
            // allows us to use v-model on our input.
            this.$emit('input', val);
        },
        value(val) {
            if (val !== this.currentValue) {
                this.currentValue = val;
            }
        }
    },
    methods: {
        focus() {
            this.$refs.input.focus();
        },
        keydown(event) {
            if (this.type === 'number') {
                if (event.key === '.' || event.key === ',') {
                    event.preventDefault();
                }
            }
        }
    }
};
</script>

