export default [
    { value: 'eus', text: 'Basque' },
    { value: 'bg', text: 'Bulgarian' },
    { value: 'ca', text: 'Catalan' },
    { value: 'zh', text: 'Chinese' },
    { value: 'hr', text: 'Croatian' },
    { value: 'cs', text: 'Czech' },
    { value: 'da', text: 'Danish' },
    { value: 'nl', text: 'Dutch' },
    { value: 'en', text: 'English' },
    { value: 'et', text: 'Estonian' },
    { value: 'fi', text: 'Finnish' },
    { value: 'fr', text: 'French' },
    { value: 'de', text: 'German' },
    { value: 'el', text: 'Greek' },
    { value: 'hu', text: 'Hungarian' },
    { value: 'it', text: 'Italian' },
    { value: 'ja', text: 'Japanese' },
    { value: 'lv', text: 'Latvian' },
    { value: 'lt', text: 'Lithuanian' },
    { value: 'mt', text: 'Maltese' },
    { value: 'no', text: 'Norwegian' },
    { value: 'pl', text: 'Polish' },
    { value: 'pt', text: 'Portuguese' },
    { value: 'ro', text: 'Romanian' },
    { value: 'ru', text: 'Russian' },
    { value: 'sr-Cyrl', text: 'Serbian (Cyrrilic)' },
    { value: 'sr-Latn', text: 'Serbian (Latin)' },
    { value: 'sk', text: 'Slovak' },
    { value: 'sl', text: 'Slovenian' },
    { value: 'es', text: 'Spanish' },
    { value: 'sv', text: 'Swedish' },
    { value: 'tr', text: 'Turkish' }
]
