<template>
    <section>
        <h2>Settings</h2>
        <b-alert v-if="error !== null" variant="danger" :show="true" dismissible>{{ error }}</b-alert>

        <ValidationObserver tag="div">
            <div class="row">
                <form class="col-12 col-md-8">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <text-input name="siteId" v-model="siteId" label="Site ID" rules="required" :readonly="true">
                                <template v-slot:explanation><span class="form-text text-muted small">Specify if using multiple sites</span></template>
                            </text-input>
                        </div>
                        <div class="col-12 col-lg-8">
                            <text-input name="cmpTitle" v-model="cmpTitle" label="Title" placeholder="Manage Your Privacy" rules="required" autocomplete="off">
                                <template v-slot:explanation><span class="form-text text-muted small">Title of the consent management popup</span></template>
                            </text-input>
                        </div>
                    </div>

                    <text-input name="cookieStatementUrl" v-model="cookieStatementUrl" label="Cookie Statement URL" placeholder="https://..." rules="required|url" autocomplete="off">
                        <template v-slot:explanation><span class="form-text text-muted small">URL on your website with cookie statement</span></template>
                    </text-input>

                    <text-input name="privacyPolicyUrl" v-model="privacyPolicyUrl" label="Privacy Policy URL" placeholder="https://..." rules="url" autocomplete="off">
                        <template v-slot:explanation><span class="form-text text-muted small">URL on your website with privacy policy</span></template>
                    </text-input>

                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="form-group">
                                <label class="form-label label-required" for="consentLanguage">Consent language</label>
                                <b-form-select class="form-control" id="consentLanguage" v-model="consentLanguage" :options="languages" aria-describedby="consentLanguageHelp"></b-form-select>
                                <small id="consentLanguageHelp" class="form-text text-muted">Language to use when showing consent management popup</small>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="form-group">
                                <label class="form-label label-required" for="publisherCC">Publisher country</label>
                                <b-form-select class="form-control" id="publisherCC" v-model="publisherCC" :options="countries" aria-describedby="publisherCCHelp" size="2"></b-form-select>
                                <small id="publisherCCHelp" class="form-text text-muted">Country where you are located / do business; determines whether GDPR applies</small>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="form-group">
                                <text-input name="ttl" v-model="ttl" label="Time-To-Live" type="number" rules="required" append="seconds" autocomplete="off">
                                    <template v-slot:explanation><span class="form-text text-muted small">How long should CMP config be cached in visitor's browser</span></template>
                                </text-input>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <text-input name="font" v-model="font" label="Font" :rules="{ regex: /^[a-z0-9 ]+$/i }" autocomplete="off">
                                <template v-slot:explanation><span class="form-text text-muted small">Font name. Should be loaded separately using (S)CSS. <code>Arial, Helvetica, sans-serif</code> always appended.</span></template>
                            </text-input>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="form-group">
                                <label class="form-label label-required" for="publisherCC">Initial page</label>
                                <b-form-select class="form-control" id="page" v-model="page" :options="pages" aria-describedby="pageHelp" size="2"></b-form-select>
                                <small id="pageHelp" class="form-text text-muted">Select which page to open when CMP is shown (* is not IAB compliant)</small>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <text-input name="logo" v-model="logo" label="Logo / favicon URL" rules="url" autocomplete="off">
                                <template v-slot:explanation><span class="form-text text-muted small">Absolute URL to (square) logo / favicon to show in CMP header title, max. dimensions 32&times;32 px.</span></template>
                            </text-input>
                        </div>
<!--                        <div class="col-12 col-lg-4">-->
<!--                            <div class="form-group">-->
<!--                                <label class="form-label" for="googleConsentMode">Google Consent Mode</label>-->
<!--                                <b-form-checkbox :value="true" v-model="googleConsentMode" id="googleConsentMode">-->
<!--                                    Google Consent Mode v2 is-->
<!--                                    {{ googleConsentMode ? 'enabled' : 'disabled' }}-->
<!--                                </b-form-checkbox>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </form>

                <div class="col-12 col-md-4">
                    <div class="row">
                        <div class="col-12 col-xl-6 mb-3">
                            <score-card v-if="gvl !== null" title="GVL Version / Policy" :value="gvl.vendorListVersion + ' / ' + gvl.tcfPolicyVersion" format="string"></score-card>
                        </div>
                        <div class="col-12 col-xl-6 mb-3">
                            <score-card title="CMP Version" :value="cmpVersion"></score-card>
                        </div>
                        <div class="col-12 mb-3" v-if="cmpVersion > 0">
                            <section class="score-card">
                                <div class="score-card-body">
                                    <h4 class="card-title">Front-end JS</h4>

                                    <text-input name="trackerUrl" v-model="trackerUrl" label="Tracker URL" placeholder="https://c.onesecondbefore.com" rules="url" autocomplete="off">
                                        <template v-slot:explanation><span class="form-text text-muted small">Analytics tracker URL. Contact us for guidance.</span></template>
                                    </text-input>

                                    <div class="row">
                                        <div class="col">
                                            <text-input name="consentUrl" v-model="consentUrl" label="Shared cookie domain URL" rules="url" autocomplete="off">
                                                <template v-slot:explanation><span class="form-text text-muted small">Specify if you want to share multiple websites under a single CMP. Visitor will be redirected to this URL to give consent. Contact us for guidance.</span></template>
                                            </text-input>
                                        </div>
                                        <div class="col-2">
                                            <a v-if="visitConsentUrl !== null" :href="visitConsentUrl" target="_blank" class="btn btn-primary" style="margin-top: 1.7rem"><i class="bx bx-link-external"></i> Visit</a>
                                        </div>
                                    </div>

                                    <p class="text-muted small">Above URLs are used to create correct JS fragments with the button below.</p>

                                    <a class="btn btn-primary btn-lg" v-b-modal.sourcecode>Show JS Code</a>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>

        <b-modal title="Front-end Javascript code" ok-only ok-title="Close" id="sourcecode" size="xl">
            <div v-if="consentUrl">
                <p>Include below JS code in the <code>&lt;head&gt;</code> of each HTML page / template of your website:</p>
                <source-code lang="js">
                <pre>
                    &lt;script crossorigin="use-credentials"&gt;
                    {{ jsCode1 }}
                    &lt;/script&gt;
                </pre>
                </source-code>
                <p>Include below JS code on the consent page on <code>{{ consentUrl + '?aid=' + (project ? project.name : 'development') + '&amp;sid=' + siteId }}</code>:</p>
                <source-code lang="js">
                <pre>
                    &lt;script crossorigin="use-credentials"&gt;
                    {{ jsCode2 }}
                    &lt;/script&gt;
                </pre>
                </source-code>
            </div>
            <div v-else>
                <p>Include below JS code in the <code>&lt;head&gt;</code> of each HTML page / template of your website:</p>
                <source-code lang="js">
                <pre>
                    &lt;script crossorigin="use-credentials"&gt;
                    {{ jsCode3 }}
                    &lt;/script&gt;
                </pre>
                </source-code>
            </div>
        </b-modal>
    </section>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import languages from '@/lib/languages';
import countries from '@/lib/countries';
import ScoreCard from '../../score-card';
import SourceCode from '../../source-code';
import TextInput from '../../text-input';
import sha1 from '@/lib/sha1.mjs';
import { ValidationObserver } from 'vee-validate';

export default {
    name: 'consent-settings',
    components: {
        ScoreCard,
        SourceCode,
        TextInput,
        ValidationObserver
    },
    props: {
        gvl: {
            required: true
        }
    },
    data() {
        return {
            error: null,

            languages,
            countries
        }
    },
    computed: {
        ...mapFields(['project', 'siteId', 'token', 'cmpVersion', 'consentLanguage', 'publisherCC', 'ttl', 'cookieStatementUrl', 'privacyPolicyUrl', 'cmpTitle', 'font', 'logo', 'trackerUrl', 'consentUrl', 'page', 'googleConsentMode']),
        configUrl() {
            return this.project ? 'https://cdn.onesecondbefore.com/cmp/' + sha1(this.project.name +'-' + this.siteId).substring(0, 8) + '.json' : null;
        },

        visitConsentUrl() {
            const search = new URLSearchParams();
            search.append('aid', this.project ? this.project.name : 'development');
            search.append('sid', this.siteId);
            search.append('target', 'https://www.onesecondbefore.com');
            search.append('show', 'true');
            return this.consentUrl + '?' + search.toString();
        },

        jsImport() {
            return `(function(o,n,e,s,b,f){
osb_queue=n.osb_queue||[];osb=n.osb||function(...args){osb_queue.push(args)};
r=new URLSearchParams(n.location.search);f=o.createElement('script');f.type='module';f.async=true;
f.src=e+'/js/v1/osb-cmp.min.mjs?aid='+s+(b!==null?'&sid='+b:'');
o.currentScript.parentNode.insertBefore(f,o.currentScript.nextSibling);
})(document,window,'${this.trackerUrl ? this.trackerUrl : 'https://cdn.onesecondbefore.com'}','${this.project ? this.project.name : 'development'}','${this.siteId}');\n`
        },

        jsCode1() {
            return this.jsImport + `osb('consent',{consentUrl:'${this.consentUrl}'});`;
        },

        jsCode2() {
            return this.jsImport +
                `osb('consent',{
configUrl:'${this.configUrl}',
target:new URLSearchParams(window.location.search).get('target')
});`;
        },

        jsCode3() {
            return this.jsImport + `osb('consent',{configUrl:'${this.configUrl}'});`;
        },

        pages() {
            return [
                {
                    text: "Without stacks / purposes *",
                    value: 0
                },
                {
                    text: "With stacks / purposes",
                    value: 1
                }
            ]

        }
    }
}
</script>