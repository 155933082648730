<template>
    <section class="score-card" :id="id">
        <div class="score-card-body">
            <h4 class="card-title">
                <span class="text-truncate">{{ title }}</span>
                <span :class="{ 'ml-2': refresh || tooltip }">
                    <i v-if="refresh" :class="{ 'bx': true, 'bx-refresh': true, 'mr-2': tooltip }" @click="refresh" v-b-tooltip.html="'Refresh data'"></i>
                    <i v-if="tooltip" class="bx bxs-help-circle" v-b-tooltip.html="tooltip"/>
                </span>
            </h4>
            <h2 :class="titleColor()">{{ formatValue(displayValue) }}</h2>
        </div>
        <div v-if="showSpinner" class="spinner-background">
            <b-spinner label="Please wait..."></b-spinner>
        </div>
    </section>
</template>
<script>
import {postData} from '@/lib/load_data';
import {formatDuration, formatPercentage} from '@/lib/formatting';

export default {
    name: 'score-card',
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        title: {
            type: String,
            required: true
        },
        value: {
            type: [String, Number],
            required: false
        },
        url: {
            type: String,
            required: false
        },
        data: {
            type: Object,
            required: false
        },
        format: {
            type: [String, Function],
            required: false,
            default: 'numeric'
        },
        color: {
            type: [String, Function],
            required: false,
            default: 'green'
        },
        tooltip: {
            type: String,
            required: false
        },
        refresh: {
            type: Function,
            required: false,
            default: null
        }
    },
    data() {
        return {
            showSpinner: false,
            remoteValue: 'N/A'
        }
    },
    computed: {
        displayValue() {
            if (this.url !== undefined) {
                return this.remoteValue !== undefined && this.remoteValue !== null ? this.remoteValue : null;
            } else {
                return this.value !== undefined && this.value !== null ? this.value : null;
            }
        }
    },
    watch: {
        title(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.load();
            }
        },
        url(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.load();
            }
        },
        data(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.load();
            }
        }
    },
    methods: {
        async load() {
            if (this.url !== undefined) {
                let tmp = await postData(this.url, this.data);
                this.remoteValue = tmp ? tmp.value : null;
            }
        },
        formatValue(value) {
            if (typeof value === 'number' && !isNaN(value)) {
                if (this.format === 'numeric') {
                    return new Intl.NumberFormat('en', {
                        useGrouping: true
                    }).format(value);
                } else if (this.format === 'percentage') {
                    return formatPercentage(value, 1);
                } else if (this.format === 'duration') {
                    return formatDuration(value);
                } else if (typeof this.format === 'function') {
                    return this.format(value);
                }
            } else if (value === null || isNaN(value) && this.format !== 'string') {
                return "N/A";
            }
            return value;
        },

        titleColor() {
            if (typeof this.color === 'string') {
                return this.color;
            } else if (typeof this.color === 'function') {
                return this.color();
            }
        }
    }
}
</script>