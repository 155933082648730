<template>
    <div :id="id" :style="style"></div>
</template>
<script>
import { init, dispose } from 'echarts';
import { formatDuration, formatUnits } from '@/lib/formatting';
import shine from './theme/shine';

export default {
    name: 'bar-chart',
    props: {
        data: {
            type: Array,
            required: true
        },
        name: {
          type: String,
          required: true
        },
        xAxis: {
            type: String,
            required: false,
            default: 'date'
        },
        formatXAxis: {
            type: Function,
            required: false
        },
        yAxis: {
            type: String,
            required: false,
            default: 'count'
        },
        yAxisType: {
            type: String,
            required: false,
            default: 'value'
        },
        width: {
            type: [String,Number],
            required: false,
            default: '100%'
        },
        height: {
            type: [String,Number],
            required: false,
            default: 500
        },
        stack: {
            type: Boolean,
            required: false,
            default: false
        },
        color: {
            type: [String, Function, Object],
            required: false
        },
        seriesNames: {
            type: Array,
            required: false,
            default() {
                return ['value'];
            }
        },
        seriesLabel: {
            type: [Function, Object, String],
            required: false
        },
        onclick: {
            type: Function,
            required: false,
            default: null
        },
        showLegend: {
            type: Boolean,
            required: false,
            default: true
        },
        legendFormatter: {
            type: Function,
            required: false,
            default: null
        }
    },
    data() {
        return {
            chart: null
        }
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.drawChart();
            }
        }
    },
    computed: {
        id() {
            return 'echart-' + this.name.toLowerCase().replace(/ /g, '_');
        },
        style() {
            return "width: " + (typeof this.width === 'number' ? this.width + 'px' : this.width) +
                '; height: ' + (typeof this.height === 'number' ? this.height + 'px' : this.height) + ';'
        }
    },
    mounted() {
        this.drawChart();
    },
    methods: {
        drawChart() {
            const self = this;

            if (this.chart !== null) {
                dispose(this.chart);
                this.chart = null;
            }
            this.chart = init(document.getElementById(this.id), shine, {
                renderer: 'svg'
            });
            window.addEventListener('resize', () => {
                this.chart.resize();
            });

            const w = document.getElementById(this.id).getBoundingClientRect().width;
            let left = '10%';
            if (w < 500) {
                left = 50;
            } else if (w > 800) {
                left = 80;
            }

            let xAxis = [];
            let series = [];
            let data = this.data;

            for (let i = 0; i < data.length; i++) {
                const x = data[i][this.xAxis];
                if (typeof this.formatXAxis === 'function') {
                    xAxis.push(this.formatXAxis(x));
                } else {
                    xAxis.push(x);
                }

                for (let j = 0; j < this.seriesNames.length; j++) {
                    if (series.length <= j) {
                        series.push([]);
                    }

                    let c = undefined;
                    if (typeof this.color === 'function') {
                        c = this.color(x, data[i][this.seriesNames[j]]);
                    } else if (typeof this.color === 'object') {
                        c = this.color[this.seriesNames[j]];
                    } else if (this.color) {
                        c = this.color;
                    }
                    series[j].push({
                        /* Don't include data points with y value 0 in log formatted y-axis */
                        value: this.yAxisType === 'log' && data[i][this.seriesNames[j]] === 0 ? undefined : data[i][this.seriesNames[j]],
                        itemStyle: {
                            color: c
                        }
                    });
                }
            }

            let chartSeries = [];
            for (let j = 0; j < this.seriesNames.length; j++) {
                let name = this.seriesNames[j];
                if (typeof this.seriesLabel === 'function') {
                    name = this.seriesLabel(this.seriesNames[j]) || this.seriesNames[j];
                } else if (typeof this.seriesLabel === 'object') {
                    name = this.seriesLabel[this.seriesNames[j]] || this.seriesNames[j];
                } else if (this.seriesLabel !== undefined) {
                    name = this.seriesLabel;
                }

                const item = {
                    name,
                    type: 'bar',
                    data: series[j]
                };
                if (this.stack) {
                    item.stack = 'stack';
                    item.barWidth = '60%';
                } else {
                    item.barWidth = (60 / this.seriesNames.length) + '%';
                }

                if (this.color && this.color[this.seriesNames[j]]) {
                    item.itemStyle = {
                        color: this.color[this.seriesNames[j]]
                    };
                }

                chartSeries.push(item);
            }

            // const toolbox = {
            //     feature: {
            //         saveAsImage: {
            //             name: this.name,
            //             title: 'Save',
            //             excludeComponents: ['toolbox', 'dataZoom']
            //         }
            //     }
            // };

            // console.log(JSON.stringify(xAxis));
            // console.log(JSON.stringify(chartSeries));
            this.chart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                // toolbox,
                grid: {
                    left
                },
                legend: this.showLegend ? {
                    formatter: self.legendFormatter ? self.legendFormatter : undefined
                } : undefined,
                xAxis: {
                    data: xAxis,
                    axisLabel: {
                        fontFamily: 'Raleway',
                        rotate: 45
                    }
                },
                yAxis: [{
                    name: this.name,
                    type: this.yAxisType,
                    minorTick: {
                        show: true
                    },
                    minorSplitLine: {
                        show: true
                    },
                    nameLocation: 'middle',
                    nameGap: 150,
                    axisLabel: {
                        fontFamily: 'Raleway',
                        formatter(d) {
                            if (self.yAxis === 'count') {
                                return formatUnits(d, 0);
                            } else if (self.yAxis === 'duration') {
                                return formatDuration(d);
                            }
                        }
                    }
                }],
                series: chartSeries
            });


            if (this.onclick) {
                this.chart.on('click', this.onclick);
            }
        }
    }
}
</script>