<template>
    <pre v-highlightjs @click="copy"><div class="raw"><slot></slot></div><code :class="{ hljs: true, lang: lang, copied: copied }"></code></pre>
</template>

<script>
    import hljs from 'highlight.js/lib/core';
    import javascript from 'highlight.js/lib/languages/javascript';
    import 'highlight.js/styles/dark.css';

    hljs.registerLanguage('javascript', javascript);

    function prettyPrint(value) {
        const lines = value.split("\n");
        let indent = null;
        const code = [];
        for (let i = 0 ; i < lines.length; i++) {
            let line = lines[i];
            if (indent !== null) {
                if (new RegExp('^\\s{' + indent + '}').test(line)) {
                    code.push(line.substring(indent));
                } else {
                    code.push(line);
                }
            } else if (/^\s+/.test(line)) {
                indent = /^\s+/.exec(line)[0].length;
                code.push(line.substring(indent));
            }
        }

        /* Remove any empty lines at the start of the code array */
        while (code.length > 0 && /^\s+$/.test(code[0])) {
            code.shift();
        }

        /* Remove any empty lines at the end of the code array */
        while (code.length > 0 && /^\s+$/.test(code[code.length - 1])) {
            code.pop();
        }

        const source = code.join('\n');
        // eslint-disable-next-line
        // console.log(source);

        return source;
    }

    function raw2formatted(el) {
        const raw = el.querySelector('.raw').textContent;
        const formatted = el.querySelector('code');
        formatted.innerHTML = raw ? hljs.highlightAuto(prettyPrint(raw)).value : '';
    }

    export default {
        name: 'source-code',
        directives: {
            highlightjs: {
                deep: true,
                bind: function (el) {
                    raw2formatted(el);
                },
                componentUpdated: function (el) {
                    raw2formatted(el);
                }
            }
        },
        props: {
            lang: {
                type: String,
                required: false,
                default: 'javascript'
            }
        },
        data() {
            return {
                copied: false
            };
        },
        methods: {
            copy() {
                if (window.isSecureContext) {
                    /* <pre> is first child of first default slot */
                    navigator.clipboard.writeText(prettyPrint(this.$slots.default[0].children[0].text)).then(() => {
                        this.copied = true;
                        /* Remove the flag again */
                        setTimeout(() => this.copied = false, 500);
                    });
                } else {
                    console.error('No access to clipboard API in non-secure context');
                }
            }
        }
    }
</script>
<style>
    .raw { display: none; }
    code.hljs:not(.no-copy) { position: relative; cursor: grab; }
    code.hljs:not(.no-copy):hover::after {
        position: absolute;
        font-family: 'boxicons';
        font-size: 1.5rem;
        content: '\ea84';
        top: 3px;
        right: 8px;
    }
    code.hljs.copied { cursor: grabbing; }
    code.hljs.copied::after {
        position: absolute;
        font-family: 'boxicons';
        font-size: 1.5rem;
        content: '\ea84';
        color: #ee0000;
        top: 3px;
        right: 8px;
        animation: blinker 0.25s linear infinite;
    }

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
</style>


