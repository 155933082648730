import moment from "moment";

export function formatNumber(value, fractionDigits = 0) {
    return !isNaN(value) ? new Intl.NumberFormat('en', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    }).format(value) : '-';
}

export function formatDate(value, pattern) {
    return moment(value).format(pattern);
}

export function formatUtcDate(value, pattern) {
    return moment.utc(value).format(pattern);
}

export function formatDuration(value) {
    function format(x) {
        return x < 10 ? '0' + x : x;
    }

    if (typeof value === "number" && !isNaN(value)) {
        let seconds = value % 60;
        let minutes = ((value - seconds) / 60) % 60;
        let hours = (value - seconds - minutes * 60) / 3600;
        return format(Math.round(hours)) + ':' + format(Math.round(minutes)) + ':' + format((Math.round(seconds * 10) / 10).toFixed(1));
    } else {
        return '-';
    }
}

export function formatPercentage(value, digits = 1) {
    return formatNumber(value * 100, digits) + ' %';
}

export function formatCurrency(amount) {
    return '€\u00a0' + formatNumber(amount);
}

export function formatUnits(value, fractionDigits = 1) {
    if (value >= 1E15) {
        return formatNumber(value / 1E15, fractionDigits) + ' Q';
    } else if (value >= 1E12) {
        return formatNumber(value / 1E12, fractionDigits) + ' T';
    } else if (value >= 1E9) {
        return formatNumber(value / 1E9, fractionDigits) + ' B';
    } else if (value >= 1E6) {
        return formatNumber(value / 1E6, fractionDigits) + ' M';
    } else if (value >= 1E3) {
        return formatNumber(value / 1E3, fractionDigits) + ' k';
    } else {
        return value;
    }
}