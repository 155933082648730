<template>
    <main>
        <div class="analysis-nav">
            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <h1>Usage &middot; <span class="green">Analytics</span></h1>
                    <month-selector v-model="date" v-if="available"></month-selector>
                </div>
            </div>
        </div>
        <section class="container-fluid">
            <b-alert v-if="error !== null" variant="danger" :show="true">{{ error }}</b-alert>
            <b-alert v-if="available === false" variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null}}</strong>.</b-alert>

            <div v-else-if="error === null && available" class="capture-hits-grid">
                <score-card title="Hits MTD" id="hits-mtd" :value="totalHits" tooltip="Number of hits for the selected month" :refresh="refreshCaptureUsage"></score-card>
                <score-card title="Hits estimated" id="hits-est" :value="estimatedHits" tooltip="Estimated number of hits at the end of the selected month" :refresh="refreshCaptureUsage"></score-card>
                <score-card title="Hits today" id="hits-today" :value="todayHits" tooltip="Number of hits for the current day" :refresh="refreshCaptureUsage"></score-card>
                <score-card title="Avg. hits per day" id="avg-hits" :value="avgHits" tooltip="Average number of hits per day for the selected month" :refresh="refreshCaptureUsage"></score-card>

                <section id="bigquery-details" v-if="bqSettings !== null">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">BigQuery details</h4>
                            <table class="table-striped w-100">
                                <tbody>
                                <tr>
                                    <th>Project</th>
                                    <td>{{ bqSettings.project_id }}</td>
                                </tr>
                                <tr>
                                    <th>Dataset</th>
                                    <td>{{ bqSettings.dataset_id }}</td>
                                </tr>
                                <tr>
                                    <th>Table name</th>
                                    <td>
                                        {{ bqSettings.table_name }}
                                        <a :href="'https://console.cloud.google.com/bigquery?folder=&organizationId=&project=' + bqSettings.project_id + '&p=' + bqSettings.project_id + '&d=' + bqSettings.dataset_id + '&t=' + bqSettings.table_name + '&page=table'" target="_blank">
                                            <i class="bx bx-link-external" title="Go to Google BigQuery"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <th>E-mail salt</th>
                                    <td>{{ bqSettings.salt }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>

                <section id="owned-domains-details" v-if="ownedDomains.length > 0">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">
                                <span class="text-truncate">Owned Domains</span>
                                <span class="ml-2">
                                    <i class="bx bxs-help-circle" v-b-tooltip="'Lists all domain names that belong to this project. Traffic between these domains is considered to belong to the same session.'"/>
                                </span>
                            </h4>
                            <ul class="table-striped pl-3 small" style="columns: 2">
                                <li v-for="od in ownedDomains" :key="'od-' + od">
                                    <a :href="'//' + od" target="_blank" rel="noopener,noreferrer" referrerpolicy="no-referrer">{{ od }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="capture-hits-chart">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">
                                <span>Analytics hits per day</span>
                                <span class="ml-2">
                                    <i class="bx bx-refresh mr-2" @click="refreshCaptureUsage()"></i>
                                    <i class="bx bxs-help-circle" v-b-tooltip title="Number of analytics hits per day"></i>
                                </span>
                            </h4>
                            <bar-chart :data="chartData" name="Analytics hits (count)" :series-names="seriesNames" :stack="true"/>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    </main>
</template>
<script>
import BarChart from '../bar-chart';
import ScoreCard from "../score-card";
import MonthSelector from '../month-selector';
import {postData} from '@/lib/load_data';
import {formatDate} from "@/lib/formatting";
import {mapFields} from "vuex-map-fields";
import BaseFormatting from "@/components/base-formatting.mjs";

export default {
    name: 'capture-hits',
    mixins: [
        BaseFormatting
    ],
    components: {
        BarChart,
        ScoreCard,
        MonthSelector
    },
    data() {
        return {
            data: [],
            chartData: [],
            seriesNames: [],
            bqSettings: null,
            ownedDomains: [],
            error: null
        }
    },
    mounted() {
        if (this.project) {
            Promise.all([
                this.loadHits(this.project.name),
                this.loadBQSettings(this.project.name),
                this.loadOwnedDomains(this.project.name)
            ]);
        }
    },
    watch: {
        project(value) {
            if (value) {
                Promise.all([
                    this.loadHits(value.name),
                    this.loadBQSettings(value.name),
                    this.loadOwnedDomains(this.project.name)
                ]);
            }
        },
        date() {
            this.loadHits(this.project.name);
        }
    },
    computed: {
        ...mapFields(['project', 'products', 'date']),
        available() {
            return this.products !== null ? this.products.includes('capture') : null;
        },
        totalHits() {
            let sum = 0;
            for (let i = 0; i < this.data.length; i++) {
                sum += this.data[i].value;
            }
            return sum;
        },
        estimatedHits() {
            /* Calculate number of expected hits based upon month-to-date hits and current time rounded to the hour */
            let current = new Date();
            let firstOfMonth = Math.round(new Date(current.getFullYear(), current.getMonth(), 1).getTime() / (3600 * 1000));
            let firstOfNextMonth = Math.round(new Date(current.getFullYear(), current.getMonth() + 1, 1).getTime() / (3600 * 1000));

            /* Which month selected ? */
            if (Math.round(this.$store.state.date.getTime() / (3600 * 1000)) >= firstOfMonth) {
                /* Current month */
                let now = Math.round(Date.now() / (3600 * 1000));
                if (now - firstOfMonth > 0) {
                    /* Current month should have at least one hour worth of data */
                    return Math.round(this.totalHits * (firstOfNextMonth - firstOfMonth) / (now - firstOfMonth));
                } else {
                    return null;
                }
            } else {
                /* Previous month */
                return this.totalHits;
            }
        },
        avgHits() {
            let sum = 0.0;
            let count = 0;
            let prevDate = null;
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].value > 0) {
                    if (this.data[i].date !== prevDate) {
                        count++;
                        prevDate = this.data[i].date;
                    }
                    sum += this.data[i].value;
                }
            }
            return count > 0 ? Math.round(sum / count) : null;
        },
        todayHits() {
            if (this.data.length > 0) {
                let current = new Date();
                let firstOfMonth = new Date(current.getFullYear(), current.getMonth(), 1).getTime();

                /* Previous months selected? */
                if (this.$store.state.date.getTime() >= firstOfMonth) {
                    /* Current month selected, sum all site_ids for the current date */
                    let sum = 0;
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].date === this.formatDate(current, 'YYYY-MM-DD')) {
                            sum += this.data[i].value;
                        }
                    }
                    return sum;
                }
            }
            return null;
        }
    },
    methods: {
        async loadHits(projectName) {
            this.error = null;
            this.data = await postData('/api/capture/hits', {
                project: projectName,
                date: formatDate(this.date, 'YYYY-MM-DD')
            });
            let count = 0;
            let result = [];
            let seriesNames = [];
            if (this.data !== null) {
                let prevDate = this.data[0].date;
                let current = {
                    date: prevDate
                };
                for (let i = 0; i < this.data.length; i++) {
                    const hit = this.data[i];

                    count += hit.value;

                    if (hit.site_id && !seriesNames.includes(hit.site_id)) {
                        seriesNames.push(hit.site_id);
                    }
                    if (prevDate !== hit.date) {
                        result.push(current);
                        prevDate = hit.date;
                        current = {
                            date: hit.date
                        };
                    }
                    current[hit.site_id] = hit.value;
                }
                result.push(current);
                //console.log(JSON.stringify(result));
                this.chartData = result;
                this.seriesNames = seriesNames.sort();
            }
            if (count === 0) {
                this.error = 'No hits for this time period.';
                this.chartData = [];
                this.seriesNames = [];
            }
        },

        async loadBQSettings(projectName) {
            this.bqSettings = await postData('/api/settings/bq', {
                project: projectName
            });
        },

        async loadOwnedDomains(projectName) {
            this.ownedDomains = await postData('/api/capture/owned-domains', {
                project: projectName
            });
        },

        async refreshCaptureUsage() {
            if (this.project) {
                await this.loadHits(this.project.name);
            }
        }
    }
}
</script>