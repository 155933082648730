<template>
    <div id="spinner-container" v-if="showSpinner">
        <div id="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
        </div>
    </div>
</template>
<script>
import emitter from '../lib/emitter';

export default {
    name: 'spinner',
    data() {
        return {
            showSpinner: false
        }
    },
    mounted() {
        emitter.on("spinner:show", () => {
            console.debug("Show spinner");
            this.showSpinner = true;
        });
        emitter.on("spinner:hide", () => {
            console.debug("Hide spinner");
            this.showSpinner = false;
        });
    }
};
</script>