<template>
    <div class="btn-group" style="height: 2.5rem">
        <button type="button" class="btn btn-secondary" @click="prevMonth">
            <i class="bx bx-chevron-left"></i>
        </button>
        <button type="button" class="btn link" style="width: 100px">{{ formatDate(month, 'MMM YYYY') }}</button>
        <button type="button" class="btn btn-secondary" @click="nextMonth" :disabled="nextMonthDisabled">
            <i class="bx bx-chevron-right"></i>
        </button>
    </div>
</template>
<script>
import { formatDate } from "@/lib/formatting";

export default {
    name: 'month-selector',
    props: {
        value: {
            type: Date,
            required: true
        }
    },
    data() {
        return {
            month: this.value
        }
    },
    computed: {
        nextMonthDisabled() {
            return new Date(this.month.getFullYear(), this.month.getMonth() + 1, 1).getTime() > Date.now();
        }
    },
    methods: {
        formatDate(date, pattern) {
            return formatDate(date, pattern);
        },
        prevMonth() {
            this.month = new Date(this.month.getFullYear(), this.month.getMonth() - 1, 1);
            this.$emit('input', this.month);
        },
        nextMonth() {
            this.month = new Date(this.month.getFullYear(), this.month.getMonth() + 1, 1);
            this.$emit('input', this.month);
        }
    }
}
</script>