<template>
    <main>
        <analysis-header title="Buying Mode Detection"></analysis-header>

        <section class="container-fluid my-3">
            <b-alert v-if="error" :show="true" variant="danger">{{ error }}</b-alert>
            <b-alert v-if="available === false" variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null}}</strong>.</b-alert>

            <div v-else-if="available" class="bmd-analytics-grid">
                <div class="card">
                    <div class="card-body">
                        <b-card-title>
                            <span>Buying Mode Detection</span>
                            <span>
                                <i class="bx bx-refresh pr-2" @click="refreshBMD()"></i>
                                <i class="bx bxs-help-circle" v-b-tooltip title="Lorem ipsum dolor sit amet"></i>
                            </span>
                        </b-card-title>

                        <funnel name="bmd" :data="bmdResults" name-from="likelihood_to_convert" value-from="journeys" :height="400"></funnel>

                        <p class="small text-center">
                            <span><strong>Very Low</strong>: 0% - 0,5%</span>
                            <span class="ml-3"><strong>Low</strong>: 0,5% - 3%</span>
                            <span class="ml-3"><strong>Medium</strong>: 3% - 15%</span>
                            <span class="ml-3"><strong>High</strong>: 15% - 100%</span>
                        </p>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <b-card-title>
                            <span>Channel Preference for <span class="green">{{ bmdResultsToShow.likelihood_to_convert }}</span></span>
                            <span>
                                <i class="bx bx-refresh mr-2" @click="refreshBMD()"></i>
                                <i class="bx bxs-help-circle" v-b-tooltip title="Lorem ipsum dolor sit amet"></i>
                            </span>
                        </b-card-title>

                        <table class="table table-hover table-bordered mt-3">
                            <thead>
                            <tr>
                                <td colspan="2">Journeys</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Journeys</td>
                                <td class="text-right">{{ formatNumber(bmdResultsToShow.journeys) }}</td>
                            </tr>
                            <tr>
                                <td>Estimated conversions</td>
                                <td class="text-right">
                                    {{ formatNumber(bmdResultsToShow.conversions_estimated * accuracy) }}
                                    -
                                    {{ formatNumber(bmdResultsToShow.conversions_estimated * (2 - accuracy)) }}
                                </td>
                            </tr>
                            <tr>
                                <td>Likelihood</td>
                                <td class="text-right">
                                    {{ formatPercentage(bmdResultsToShow.conversions_estimated / bmdResultsToShow.journeys, 1) }}
                                </td>
                            </tr>
                            </tbody>
                            <thead>
                            <tr>
                                <td colspan="2">Paid Search</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Estimated conversions</td>
                                <td class="text-right">{{ formatNumber(bmdResultsToShow.conversions_estimated_paid_search) }}</td>
                            </tr>
                            <tr>
                                <td>Improvement</td>
                                <td class="text-right">{{ formatPercentage((bmdResultsToShow.conversions_estimated_paid_search - bmdResultsToShow.conversions_estimated) / bmdResultsToShow.conversions_estimated) }}</td>
                            </tr>
                            </tbody>
                            <thead>
                            <tr>
                                <td colspan="2">Push Notifications</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Estimated conversions</td>
                                <td class="text-right">{{ formatNumber(bmdResultsToShow.conversions_estimated_push_notifications) }}</td>
                            </tr>
                            <tr>
                                <td>Improvement</td>
                                <td class="text-right">{{ formatPercentage((bmdResultsToShow.conversions_estimated_push_notifications - bmdResultsToShow.conversions_estimated) / bmdResultsToShow.conversions_estimated) }}</td>
                            </tr>
                            </tbody>
                            <thead>
                            <tr>
                                <td colspan="2">Email</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Estimated Conversions</td>
                                <td class="text-right">{{ formatNumber(bmdResultsToShow.conversions_estimated_email) }}</td>
                            </tr>
                            <tr>
                                <td>Improvement</td>
                                <td class="text-right">{{ formatPercentage((bmdResultsToShow.conversions_estimated_email - bmdResultsToShow.conversions_estimated) / bmdResultsToShow.conversions_estimated) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script>
import AnalysisHeader from './analysis-header';
import {postData} from "@/lib/load_data";
import {mapState} from "vuex";
import Funnel from '../funnel';
import emitter from '@/lib/emitter';
import BaseFormatting from "@/components/base-formatting.mjs";

export default {
    name: 'buying-mode-detection',
    mixins: [
        BaseFormatting
    ],
    components: {
        AnalysisHeader,
        Funnel
    },
    data() {
        return {
            error: null,

            accuracy: 0.95,
            likelihood: "Very Low",

            bmdResults: []
        }
    },
    watch: {
        project() {
            this.queryBMD();
        }
    },
    computed: {
        ...mapState(['project', 'products', 'timebase', 'start_date', 'end_date']),
        available() {
            return this.products !== null ? this.products.includes('buying-mode-detection') : null;
        },
        bmdResultsToShow() {
            for (let i = 0; i < this.bmdResults.length; i++) {
                if (this.bmdResults[i].likelihood_to_convert === this.likelihood) {
                    return this.bmdResults[i];
                }
            }
            return {};
        }
    },
    mounted() {
        emitter.on('funnel:click:bmd', (payload) => {
            this.likelihood = payload.name;
        });
        this.queryBMD(false);
    },
    methods: {
        async queryBMD(refresh = false) {
            if (this.project) {
                this.bmdResults = await postData('/api/analysis/buying-mode-detection', {
                    project: this.project.name,
                    timebase: this.timebase,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    refresh
                });
            }
        },
        async refreshBMD() {
            return await this.queryBMD(true);
        }
    }
}
</script>