<template>
    <div class="d-flex">
        <div style="width: 140px">
            <b-input-group>
                <b-form-input
                    v-model="start_date"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    readonly
                    class="form-control-sm"
                ></b-form-input>
                <b-input-group-append>
                    <b-form-datepicker
                        id="start_date"
                        v-model="start_date"
                        size="sm"
                        :min="minStart"
                        :max="maxStart"
                        button-only
                        right
                    ></b-form-datepicker>
                </b-input-group-append>
            </b-input-group>
        </div>
        <span class="mx-2">-</span>
        <div style="width: 140px">
            <b-input-group>
                <b-form-input
                    v-model="end_date"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    readonly
                    class="form-control-sm"
                ></b-form-input>
                <b-input-group-append>
                    <b-form-datepicker
                        id="end_date"
                        v-model="end_date"
                        size="sm"
                        :min="minEnd"
                        :max="maxEnd"
                        button-only
                        right
                    ></b-form-datepicker>
                </b-input-group-append>
            </b-input-group>
        </div>
    </div>
</template>
<script>
import {mapFields} from "vuex-map-fields";
import moment from "moment";

export default {
    name: 'date-range',
    computed: {
        ...mapFields([
            'start_date',
            'end_date',
        ]),
        minStart() {
            return new Date(2020, 1-1, 1);
        },
        maxStart() {
            const e = moment.utc(this.end_date).toDate();
            return new Date(e.getFullYear(), e.getMonth(), e.getDate() - 1);
        },
        minEnd() {
            return moment.utc(this.start_date).toDate();
        },
        maxEnd() {
            const now = new Date();
            /* Last day current month */
            return new Date(now.getFullYear(), now.getMonth() + 1, 0);
        }
    }
}
</script>