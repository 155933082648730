<template>
    <div class="d-flex w-100 align-items-center justify-content-center" style="height: calc(100vh - 67px)">
        <p class="text-center">Dashboard is opened in a <a :href="dashboardUrl" target="_osb_dashboard">new window</a>.</p>
    </div>
</template>
<script>
import {mapFields} from "vuex-map-fields";
import {postData} from "@/lib/load_data";

export default {
    name: 'hex-dashboard',
    data() {
        return {
            dashboardId: null,
        }
    },
    computed: {
        ...mapFields(['project', 'error']),
        productName() {
            return this.$route.params.productName;
        },
        category() {
            return this.$route.params.category;
        },
        dashboardUrl() {
            return this.dashboardId !== null ? "https://eu.hex.tech/onesecondbefore/app/" + this.dashboardId + "/latest?embedded=true" : null;
        },
    },
    watch: {
        async project(newValue) {
            await this.getDashboard(newValue.name);
        },
        async productName() {
            await this.getDashboard(this.project.name);
        },
        async category() {
            await this.getDashboard(this.project.name);
        }
    },
    async mounted() {
        if (this.project) {
            await this.getDashboard(this.project.name);
        }
    },
    methods: {
        async getDashboard(projectName) {
            const productName = this.$route.params.productName;
            const category = this.$route.params.category;
            const response = await postData('/api/hex/dashboard', {
                project: projectName,
                product: productName,
                category
            });
            if (response && response.success) {
                this.dashboardId = response.dashboard_id;
                window.open(this.dashboardUrl, '_osb_dashboard');
                this.error = null;
                // return response;
            } else {
                this.error = response.error;
            }
            return null;
        },
    }
}
</script>