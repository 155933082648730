<template>
    <main>
        <div class="analysis-nav">
            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <h1>Management &middot; <span class="green">Workflows</span></h1>
                    <month-selector v-model="date" v-if="available"></month-selector>
                </div>
            </div>
        </div>

        <section class="container-fluid">
            <b-alert v-if="error !== null" variant="danger" :show="true">{{ error }}</b-alert>

            <b-alert v-if="available === false" variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null}}</strong>.</b-alert>
            <div v-else-if="available" class="transfer-grid">
                <score-card title="Queued" :value="result.queued" style="grid-column: 1; grid-row: 1" tooltip="Number of Workflows tasks that are in the queue and will be executed when resources are available"></score-card>
                <score-card title="Running" :value="result.running" style="grid-column: 2; grid-row: 1" tooltip="Number of Workflows tasks that are currently being executed"></score-card>
                <score-card title="Up for retry" :value="result.up_for_retry" style="grid-column: 1; grid-row: 2" tooltip="Number of Workflows tasks that failed and will be retried later"></score-card>
                <score-card title="Skipped" :value="result.skipped" style="grid-column: 2; grid-row: 2" tooltip="Number of Workflows tasks that were skipped (due to flow control in the job)"></score-card>
                <score-card title="Failed" :value="result.failed" style="grid-column: 1; grid-row: 3" tooltip="Number of Workflows tasks that failed and will not be retried anymore"></score-card>
                <score-card title="Success" :value="result.success" style="grid-column: 2; grid-row: 3" tooltip="Number of Workflows tasks that completed successfully"></score-card>

                <div v-if="jobsOverview.length > 0" class="card jobs-overview-table">
                    <div class="card-body">
                        <h4 class="card-title">
                            <span>Jobs overview</span>
                            <i class="bx bxs-help-circle" v-b-tooltip title="Overview of all the jobs that are configured including all task states"></i>
                        </h4>

                        <b-table id="jobs-overview-table" bordered class="mt-3" :fixed="true" :items="jobsOverview" :per-page="pageSize" :current-page="currentPage" :busy="busy" :responsive="true" :fields="[
                            { key: 'job', label: 'Job', class: 'text-truncate' },
                            { key: 'queued', label: 'Queued', class: 'text-right'  },
                            { key: 'running', label: 'Running', class: 'text-right'  },
                            { key: 'up_for_retry', label: 'Up For Retry', class: 'text-right'  },
                            { key: 'failed', label: 'Failed', class: 'text-right'  },
                            { key: 'success', label: 'Success', class: 'text-right'  },
                            { key: 'sum_duration', label: 'Total Duration', class: 'text-right' }
                        ]">
                            <template v-slot:table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                </div>
                            </template>
                            <template v-slot:table-colgroup>
                                <col style="width: 38%">
                                <col style="width: 10%">
                                <col style="width: 10%">
                                <col style="width: 10%">
                                <col style="width: 10%">
                                <col style="width: 10%">
                                <col style="width: 12%">
                            </template>
                            <template v-slot:cell(job)="data">
                                <router-link :to="'/management/transfer/tasks/' + data.item.job" custom v-slot="{ href, navigate }">
                                    <a :href="href" :title="data.item.task_id" @click="navigate">{{ data.item.job }}</a>
                                </router-link>
                                <br><small>{{ data.item.description }}</small>
                            </template>
                            <template v-slot:cell(sum_duration)="data">{{ formatDuration(data.item.sum_duration) }}</template>
                        </b-table>
                        <b-pagination v-if="jobsOverview.length > pageSize"
                                      v-model="currentPage"
                                      :total-rows="jobsOverview.length"
                                      :per-page="pageSize"
                                      aria-controls="jobs-overview-table"
                        ></b-pagination>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script>
import ScoreCard from "../../score-card";
import MonthSelector from "../../month-selector";
import { postData } from "@/lib/load_data";
import { formatDate } from "@/lib/formatting";
import { mapFields } from "vuex-map-fields";
import BaseFormatting from "@/components/base-formatting.mjs";

export default {
    name: 'transfer-jobs-overview',
    mixins: [
        BaseFormatting
    ],
    components: {
        MonthSelector,
        ScoreCard
    },
    data() {
        return {
            result: {
                queued: 0,
                running: 0,
                up_for_retry: 0,
                skipped: 0,
                failed: 0,
                success: 0
            },
            jobsOverview: [],
            currentPage: 1,
            pageSize: 20,
            busy: false,
            error: null
        }
    },
    mounted() {
        this.loadData();
    },
    watch: {
        project() {
            this.loadData();
        },
        date() {
            this.loadData();
        }
    },
    computed: {
        ...mapFields(['project','date','products']),
        available() {
            return this.products !== null ? this.products.includes('transfer') : null;
        }
    },
    methods: {
        async loadData() {
            this.error = null;
            if (this.project) {
                this.busy = true;
                [ this.result, this.jobsOverview ] = await Promise.all([
                    postData('/api/transfer/jobs/states', {
                        project: this.project.name,
                        date: formatDate(this.date, 'YYYY-MM-DD')
                    }),
                    postData('/api/transfer/jobs/overview', {
                        project: this.project.name,
                        date: formatDate(this.date, 'YYYY-MM-DD')
                    })
                ]);
                if (this.jobsOverview.length === 0) {
                    this.error = "No jobs for the given time period.";
                }
                this.busy = false;
            }
        }
    }
}
</script>