<template>
    <main>
        <div class="analysis-nav">
            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <h1>Media Planner</h1>
                </div>
            </div>
        </div>

        <section class="container-fluid">
            <b-alert v-if="error !== null" variant="danger" :show="true">{{ error }}</b-alert>

            <b-alert v-if="available === false" variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null}}</strong>.</b-alert>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="form-label label-required" for="platform">Platform</label>
                                <treeselect v-model="platform" :multiple="true" :options="platforms" />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="form-label label-required" for="browser">Browser</label>
                                <b-form-select class="form-control" v-model="browser" name="browser" id="browser"></b-form-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <text-input v-model="postalcode" name="postalcode" label="Postal code"></text-input>
                            <text-input v-model="city" name="city" label="City"></text-input>
                        </div>
                        <div class="col-6">
                            <text-input v-model="region" name="region" label="Region"></text-input>
                            <div class="form-group">
                                <label class="form-label label-required" for="country">Country</label>
                                <b-form-select class="form-control" id="country" v-model="country" :options="countries"></b-form-select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-label label-required" for="display_format">Display formats</label>
                        <treeselect v-model="displayFormat" :multiple="true" :options="displayFormats" />
                    </div>
                    <div class="form-group">
                        <label class="form-label label-required" for="inventory_category">Inventory category</label>
                        <b-form-select class="form-control" v-model="inventory" name="inventory_category" label="inventory_category"></b-form-select>
                    </div>
                    <div class="form-group">
                        <label class="form-label label-required" for="audience_segments">Audience segments</label>
                        <treeselect v-model="audience" :multiple="true" :options="audiences" />
                    </div>

                    <b-button variant="success" @click="submit()">Submit</b-button>
                </div>
            </div>
        </section>
    </main>
</template>
<script>
import BaseFormatting from "@/components/base-formatting.mjs";
import TextInput from "@/components/text-input";
import {mapFields} from "vuex-map-fields";
import countries from "@/lib/countries";
import Treeselect from '@riophae/vue-treeselect';
import {getDataUnauthorized} from "@/lib/load_data";

export default {
    name: 'media-planner-form',
    mixins: [
        BaseFormatting
    ],
    components: {
        TextInput,
        Treeselect
    },
    computed: {
        ...mapFields(['project','date','products']),
        available() {
            return this.products !== null ? this.products.includes('transfer') : null;
        },
        countries() {
            return countries;
        }
    },
    data() {
        return {
            error: null,

            platforms: [],
            displayFormats: [],
            audiences: [],

            platform: null,
            browser: null,
            postalcode: null,
            city: null,
            region: null,
            country: null,
            displayFormat: null,
            inventory: null,
            audience: null
        };
    },
    async mounted() {
        this.platforms = await getDataUnauthorized('/data/platforms.json');
        this.displayFormats = await getDataUnauthorized('/data/display-formats.json');
        this.audiences = await getDataUnauthorized('/data/iab-content-taxonomy-mapping.json');
    }
}
</script>