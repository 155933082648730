<template>
    <div class="analysis-nav">
        <div class="analysis-header">
            <h1>Analysis &middot; <span class="green">{{ title }}</span></h1>
            <div class="d-flex">
                <b-dropdown id="dropdown-segment" text="Segment" variant="primary" size="sm" class="mr-5">
                    <b-dropdown-group id="dropdown-group-1" header="Journeys:">
                        <b-dropdown-item-button>
                            <b-form-checkbox name="segment" value="successful" v-model="segments">Successful</b-form-checkbox>
                        </b-dropdown-item-button>
                        <b-dropdown-item-button>
                            <b-form-checkbox name="segment" value="unsuccessful" v-model="segments">Unsuccessful</b-form-checkbox>
                        </b-dropdown-item-button>
                    </b-dropdown-group>
                </b-dropdown>

                <date-range></date-range>

                <div>
                    <b-form-radio-group
                        v-model="timebase"
                        :options="[{ text: 'Touchpoint', value: 'touchpoint' }, { text: 'Conversion', value: 'conversion' }]"
                        buttons
                        button-variant="outline-primary"
                        name="timebase"
                        size="sm"
                        class="ml-5"
                    ></b-form-radio-group>
                    <i class="bx bxs-help-circle ml-2" v-b-tooltip title="When you choose touchpoint, all conversions will be reported on the date of the touchpoint, when you choose conversion, all conversions will be displayed according to the date of the conversion."></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DateRange from '../date-range';
import {mapFields} from "vuex-map-fields";

export default {
    name: 'analysis-header',
    components: {
        DateRange
    },
    props: {
        title: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            error: null,
        };
    },
    computed: {
        ...mapFields([
            'project',
            'segments',
            'timebase'
        ])
    }
}
</script>