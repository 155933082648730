<template>
    <div :id="id" :style="style"></div>
</template>
<script>
import { init } from 'echarts';
import { formatNumber } from "@/lib/formatting";
import emitter from '@/lib/emitter';

export default {
    name: 'funnel',
    props: {
        data: {
            type: Array,
            required: true
        },
        name: {
          type: String,
          required: true
        },
        width: {
            type: [String,Number],
            required: false,
            default: '100%'
        },
        height: {
            type: [String,Number],
            required: false,
            default: 500
        },
        nameFrom: {
            type: String,
            required: false,
            default: 'name'
        },
        valueFrom: {
            type: String,
            required: false,
            default: 'value'
        }
    },
    data() {
        return {
            chart: null
        }
    },
    watch: {
        data() {
            this.drawChart();
        }
    },
    computed: {
        id() {
            return 'echart-' + this.name.toLowerCase().replace(/ /g, '_');
        },
        style() {
            return "width: " + (typeof this.width === 'number' ? this.width + 'px' : this.width) +
                '; height: ' + (typeof this.height === 'number' ? this.height + 'px' : this.height) + ';'
        }
    },
    mounted() {
        this.drawChart();
    },
    methods: {
        drawChart() {
            let self = this;
            if (this.chart === null) {
                this.chart = init(document.getElementById(this.id), null, {
                    renderer: 'svg'
                });
                window.addEventListener('resize', () => {
                    this.chart.resize();
                });
            }


            this.chart.on('click', function (event) {
                const eventName = 'funnel:click:' + self.name;
                console.log(eventName, event);
                emitter.emit(eventName, event);
            });

            let chartData = [];
            for (let j = 0; j < this.data.length; j++) {
                chartData.push({
                    name: this.data[j][this.nameFrom],
                    value: this.data[j][this.valueFrom],
                })
            }

            this.chart.setOption({
                tooltip: {},
                series: [{
                    name: this.name,
                    label: {
                        show: true,
                        position: 'inside',
                        formatter: (params) => {
                            return params.name + '\n' + formatNumber(params.value);
                        },
                        fontFamily: 'Raleway',
                        fontSize: 14,
                        fontWeight: 'bold'
                    },
                    type: 'funnel',
                    data: chartData,
                    gap: 2
                }]
            });
        }
    }
}
</script>