<template>
    <main>
        <div class="analysis-nav d-flex justify-content-between align-items-center">
            <h1>Homepage</h1>
        </div>
        <section v-if="error" class="container-fluid">
            <b-alert :show="true" variant="danger">
                <i class="bx bx-error"></i>
                {{ error }}
            </b-alert>
        </section>

        <div v-else class="col-12 col-md-8 offset-md-2">
            <div class="d-flex flex-wrap">
                <div class="card product" v-for="p in products" :key="p">
                    <div class="card-header">{{ productName(p) }}</div>
                    <div class="card-body">
                        <router-link v-if="analysis(p)" :to="analysis(p)" custom v-slot="{ href, navigate }">
                            <a :href="href" @click="navigate">
                                <img :src="'/images/products/' + p + '.svg'">
                            </a>
                        </router-link>
                        <router-link v-else-if="usage(p)" :to="usage(p)" custom v-slot="{ href, navigate }">
                            <a :href="href" @click="navigate">
                                <img :src="'/images/products/' + p + '.svg'">
                            </a>
                        </router-link>
                        <img v-else :src="'/images/products/' + p + '.svg'">
                    </div>
                    <div class="card-footer">
                        <div class="flex-gap justify-content-between">
                            <router-link v-if="analysis(p)" :to="analysis(p)" custom v-slot="{ href, navigate }">
                                <a :href="href" @click="navigate" class="btn btn-secondary btn-sm">Analysis</a>
                            </router-link>

                            <router-link v-if="usage(p)" :to="usage(p)" custom v-slot="{ href, navigate }">
                                <a :href="href" @click="navigate" class="btn btn-secondary btn-sm">Usage</a>
                            </router-link>

                            <router-link v-if="management(p)" :to="management(p)" custom v-slot="{ href, navigate }">
                                <a :href="href" @click="navigate" class="btn btn-secondary btn-sm">Management</a>
                            </router-link>

                            <router-link v-if="dashboard(p)" :to="dashboard(p)" custom v-slot="{ href, navigate }">
                                <a :href="href" @click="navigate" class="btn btn-secondary btn-sm">
                                   Dashboard
                                </a>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="card product" v-for="p in disabledProducts" :key="p">
                    <div class="card-header">{{ productName(p) }}</div>
                    <div class="card-body">
                        <img src="/images/products/analytics.svg" :alt="productName(p)">
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between">
                            <a class="btn btn-secondary btn-sm" :href="'mailto:info@onesecondbefore.com?subject=' + encodeURI('Let\'s discuss ' + productName(p))">Usage</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import { mapFields } from 'vuex-map-fields';

const PRODUCTS = {
    'ad-effectiveness': {
        name: 'Ad Effectiveness'
    },
    'analytics': {
        name: 'Analytics',
        analysis: '/analysis/journey-analytics'
    },
    'buying-mode-detection': {
        name: 'Buying Mode Detection',
        analysis: '/analysis/buying-mode-detection'
    },
    'capture': {
        name: 'Analytics',
        usage: '/usage/capture',
        management: '/management/capture'
    },
    'channel-preference': {
        name: 'Channel Preference'
    },
    'consent': {
        name: 'Consent',
        analysis: '/dashboard/consent/consent%20wall',
        management: '/management/consent'
    },
    'cross-device-matching': {
        name: 'Cross-Device Matching',
        analysis: '/analysis/cross-device-matching'
    },
    'data-driven-conversion-attribution': {
        name: 'Data-Driven Conversion Attribution',
        analysis: '/analysis/data-driven-conversion-attribution'
    },
    'data-driven-micro-attribution': {
        name: 'Data-Driven Micro Attribution',
        analysis: '/analysis/data-driven-micro-attribution'
    },
    'first-party-audiences': {
        name: 'First-Party Audiences'
    },
    'media-planner': {
        name: 'Media Planner',
        management: '/media-planner'
    },
    'return-ratio-reduction': {
        name: 'Return Ratio Reduction'
    },
    'transfer': {
        name: 'Workflows',
        management: '/management/transfer/jobs',
        usage: '/usage/transfer',
        dashboard: '/dashboard'
    }
}

export default {
    name: 'homepage',
    data() {
        return {
            error: null,
            message: null,
        };
    },
    computed: {
        ...mapFields(['project', 'products']),
        disabledProducts() {
            return this.project?.name === 'na' ? ['first-party-audiences'] : [];
        }
    },
    watch: {
        products(value) {
            this.error = value.length === 0 ? 'There are no products assigned to this project.' : null;
        }
    },
    methods: {
        productName(product) {
            return PRODUCTS[product]?.name || 'Unknown';
        },
        analysis(product) {
            return this.project?.permissions?.includes('analyst') || this.project?.permissions?.includes('manager') || this.project?.permissions?.includes('admin') ? PRODUCTS[product]?.analysis : null;
        },
        management(product) {
            return this.project?.permissions?.includes('manager') || this.project?.permissions?.includes('admin') ? PRODUCTS[product]?.management : null;
        },
        usage(product) {
            return this.project?.permissions?.includes('analyst') || this.project?.permissions?.includes('manager') || this.project?.permissions?.includes('admin') ? PRODUCTS[product]?.usage : null;
        },
        dashboard(product) {
            return this.project?.permissions?.includes('analyst') || this.project?.permissions?.includes('manager') || this.project?.permissions?.includes('admin') ? PRODUCTS[product]?.dashboard : null;
        }
    }
}
</script>