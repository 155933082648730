<template>
    <div><!-- Nothing here --></div>
</template>
<script>
export default {
    name: 'logout',
    mounted() {
        const self = this;
        let auth2 = window.gapi && window.gapi.auth2 ? window.gapi.auth2.getAuthInstance() : null;
        if (auth2) {
            auth2.signOut().then(function () {
                self.$store.commit('logout');
                self.$nextTick(() => {
                    self.$router.push('/');
                });
            });
        } else {
            self.$store.commit('logout');
            self.$nextTick(() => {
                self.$router.push('/');
            });
        }
    }
}
</script>