<template>
    <main>
        <analysis-header title="Results"></analysis-header>

        <section class="container-fluid my-3">
            <b-alert v-if="error" :show="true" variant="danger">{{ error }}</b-alert>
            <b-alert v-if="available === false" variant="danger" :show="true">Not available for project <strong>{{ project ? project.name : null}}</strong>.</b-alert>

            <div v-else-if="available" class="results-grid">
                <b-card class="results-table">
                    <b-card-title>
                        <span>Results</span>
                        <i class="bx bxs-help-circle" v-b-tooltip title="Results according to the attribution model"></i>
                    </b-card-title>

                    <b-table hover bordered :items="resultsGroupedByChannel" :fields="[
                        { key: 'channel', label: 'Channel' },
                        { key: 'sessions', label: 'Sessions', tdClass: 'text-right', thClass: 'text-right' },
                        { key: 'conversions_last_touch', label: 'Last Touch', tdClass: 'text-right', thClass: 'text-right' },
                        { key: 'conversions_data_driven', label: 'Data Driven', tdClass: 'text-right', thClass: 'text-right' },
                        { key: 'conversions_delta', label: 'Δ', tdClass: 'text-right', thClass: 'text-right' },
                        { key: 'revenue_last_touch', label: 'Last Touch', tdClass: 'text-right', thClass: 'text-right' },
                        { key: 'revenue_data_driven', label: 'Data Driven', tdClass: 'text-right', thClass: 'text-right' },
                        { key: 'revenue_delta', label: 'Δ', tdClass: 'text-right', thClass: 'text-right' }
                    ]">
                        <template #thead-top="">
                            <b-tr>
                                <b-th colspan="2"></b-th>
                                <b-th colspan="3" class="text-center">Conversions</b-th>
                                <b-th colspan="3" class="text-center">Revenue</b-th>
                            </b-tr>
                        </template>
                        <template #cell(sessions)="data">
                            {{ formatNumber(data.value) }}
                        </template>
                        <template #cell(conversions_last_touch)="data">
                            {{ formatNumber(data.value) }}
                        </template>
                        <template #cell(conversions_data_driven)="data">
                            {{ formatNumber(data.value) }}
                        </template>
                        <template #cell(conversions_delta)="data">
                            {{ formatPercentage((data.item.conversions_data_driven - data.item.conversions_last_touch) / data.item.conversions_last_touch)}}
                        </template>
                        <template #cell(revenue_last_touch)="data">
                            {{ formatCurrency(data.value) }}
                        </template>
                        <template #cell(revenue_data_driven)="data">
                            {{ formatCurrency(data.value) }}
                        </template>
                        <template #cell(revenue_delta)="data">
                            {{ formatPercentage((data.item.revenue_data_driven - data.item.revenue_last_touch) / data.item.revenue_last_touch)}}
                        </template>
                        <template #custom-foot="data">
                            <b-tr>
                                <b-th>Total</b-th>
                                <b-th class="text-right">{{ formatNumber(data.items.reduce((total, item) => total + item.sessions, 0)) }}</b-th>
                                <b-th class="text-right">{{ formatNumber(data.items.reduce((total, item) => total + item.conversions_last_touch, 0)) }}</b-th>
                                <b-th class="text-right">{{ formatNumber(data.items.reduce((total, item) => total + item.conversions_data_driven, 0)) }}</b-th>
                                <b-th class="text-right">{{ formatPercentage((data.items.reduce((total, item) => total + item.conversions_data_driven, 0) - data.items.reduce((total, item) => total + item.conversions_last_touch, 0)) / data.items.reduce((total, item) => total + item.conversions_data_driven, 0)) }}</b-th>
                                <b-th class="text-right">{{ formatCurrency(data.items.reduce((total, item) => total + item.revenue_last_touch, 0)) }}</b-th>
                                <b-th class="text-right">{{ formatCurrency(data.items.reduce((total, item) => total + item.revenue_data_driven, 0)) }}</b-th>
                                <b-th class="text-right">{{ formatPercentage((data.items.reduce((total, item) => total + item.revenue_data_driven, 0) - data.items.reduce((total, item) => total + item.revenue_last_touch, 0)) / data.items.reduce((total, item) => total + item.revenue_data_driven, 0)) }}</b-th>
                            </b-tr>
                        </template>
                    </b-table>
                </b-card>

                <b-card class="results-chart">
                    <b-card-title>
                        <span v-if="type === 'conversions'">Conversions per Channel</span>
                        <span v-else-if="type === 'revenue'">Revenue per Channel (in €)</span>
                        <i class="bx bxs-help-circle ml-2" v-b-tooltip title="Results according to the attribution model"></i>
                    </b-card-title>

                    <bar-chart :data="chartData" :height="400" :seriesNames="['last_touch','data_driven']" name="Results according to the attribution model"></bar-chart>

                    <b-form-group class="mt-3">
                        <b-form-radio-group
                            id="type"
                            v-model="type"
                            :options="[{
                                text: 'Conversions', value: 'conversions'
                            }, {
                                text: 'Revenue', value: 'revenue'
                            }]"
                            buttons
                            name="radios-btn-default"
                        ></b-form-radio-group>
                    </b-form-group>
                </b-card>

                <b-card class="results-overview-in-time">
                    <b-card-title>
                        <span v-if="type === 'conversions'">Conversions per Day</span>
                        <span v-else-if="type === 'revenue'">Revenue per Day (in €)</span>
                        <i class="bx bxs-help-circle ml-2" v-b-tooltip title="Lorem ipsum dolor sit amet"></i>
                    </b-card-title>

                    <div class="row">
                        <div class="col-4 col-md-3">
                            <h3>Choose a Channel:</h3>
                            <b-form-radio-group
                                v-model="channel"
                                :options="channels"
                                buttons
                                stacked
                                name="channel"
                                button-variant="outline-primary"
                            ></b-form-radio-group>
                        </div>
                        <div class="col-8 col-md-9">
                            <h3>
                                <template v-if="type === 'conversions'">Conversions per Day </template>
                                <template v-if="type === 'revenue'">Revenue per Day </template>
                                for
                                <template v-if="channel === null">All Channels</template>
                                <span v-else class="green">{{ channel }}</span>:
                            </h3>
                            <bar-chart :data="chartDataTime" :height="400" :seriesNames="['last_touch','data_driven'] " name="Results according to the attribution model per day"></bar-chart>
                        </div>
                    </div>

                    <b-form-group class="mt-3">
                        <b-form-radio-group
                            id="type"
                            v-model="type"
                            :options="[{
                                text: 'Conversions', value: 'conversions'
                            }, {
                                text: 'Revenue', value: 'revenue'
                            }]"
                            buttons
                            name="radios-btn-default"
                        ></b-form-radio-group>
                    </b-form-group>
                </b-card>

                <b-card class="result-return-on-investment">
                    <b-card-title>
                        <span>Return on Investment (%)</span>
                        <i class="bx bxs-help-circle" v-b-tooltip title="Return on Investment = (revenue - cost) / cost"></i>
                    </b-card-title>

                    <bar-chart2 name="roi" :series-names="['Data Driven','Last Touch']" :data="[{
                            x: 'Paid Search',
                            'Data Driven': 7.2,
                            'Last Touch': 9.5
                        }, {
                            x: 'Affiliate',
                            'Data Driven': 4.2,
                            'Last Touch': 1.2
                        }, {
                            x: 'Social',
                            'Data Driven': 15.1,
                            'Last Touch': 7.2
                        }, {
                            x: 'Remarketing',
                            'Data Driven': 5.1,
                            'Last Touch': 5.2
                        }]"></bar-chart2>
                </b-card>
            </div>
        </section>
    </main>
</template>
<script>
import AnalysisHeader from './analysis-header';
import BarChart from '../bar-chart';
import {mapState} from "vuex";
import {postData} from "@/lib/load_data";
import BaseFormatting from "@/components/base-formatting.mjs";

export default {
    name: 'analysis-results',
    mixins: [
        BaseFormatting
    ],
    components: {
        AnalysisHeader,
        BarChart
    },
    data() {
        return {
            error: null,
            type: 'conversions',
            channel: null,

            results: []
        }
    },
    mounted() {
        this.queryResults();
    },
    watch: {
        project() {
            this.queryResults();
        },
        timebase() {
            this.queryResults();
        },
        start_date() {
            this.queryResults();
        },
        end_date() {
            this.queryResults();
        },
        type() {

        }
    },
    computed: {
        ...mapState(['project', 'products', 'timebase', 'start_date', 'end_date']),
        available() {
            return this.products !== null ? this.products.includes('analytics') : null;
        },
        channels() {
            const c = [];
            for (let i = 0; i < this.results.length; i++) {
                if (! c.includes(this.results[i].channel)) {
                    c.push(this.results[i].channel);
                }
            }
            return c;
        },
        resultsGroupedByChannel() {
            const tmp = {};
            for (let i = 0; i < this.results.length; i++) {
                const r = this.results[i];
                if (! tmp[r.channel]) {
                    tmp[r.channel] = {
                        sessions: 0,
                        conversions_last_touch: 0,
                        revenue_last_touch: 0,
                        conversions_data_driven: 0,
                        revenue_data_driven: 0
                    }
                }

                tmp[r.channel].sessions += r.sessions;
                tmp[r.channel].conversions_last_touch += r.conversions_last_touch;
                tmp[r.channel].revenue_last_touch += r.revenue_last_touch;
                tmp[r.channel].conversions_data_driven += r.conversions_data_driven;
                tmp[r.channel].revenue_data_driven += r.revenue_data_driven;
            }

            const r = [];
            for (let channel in tmp) {
                r.push({
                    channel: channel,
                    sessions: tmp[channel].sessions,
                    conversions_last_touch: tmp[channel].conversions_last_touch,
                    revenue_last_touch: tmp[channel].revenue_last_touch,
                    conversions_data_driven: tmp[channel].conversions_data_driven,
                    revenue_data_driven: tmp[channel].revenue_data_driven
                });
            }

            return r;
        },

        chartData() {
            const tmp = {};
            for (let i = 0; i < this.results.length; i++) {
                const r = this.results[i];
                if (! tmp[r.channel]) {
                    tmp[r.channel] = {
                        conversions_last_touch: 0,
                        revenue_last_touch: 0,
                        conversions_data_driven: 0,
                        revenue_data_driven: 0
                    }
                }

                tmp[r.channel].conversions_last_touch += r.conversions_last_touch;
                tmp[r.channel].revenue_last_touch += r.revenue_last_touch;
                tmp[r.channel].conversions_data_driven += r.conversions_data_driven;
                tmp[r.channel].revenue_data_driven += r.revenue_data_driven;
            }

            const r = [];
            for (let channel in tmp) {
                if (this.type === 'conversions') {
                    r.push({
                        x: channel,
                        'last_touch': tmp[channel].conversions_last_touch,
                        'data_driven': tmp[channel].conversions_data_driven,
                    });
                } else if (this.type === 'revenue') {
                    r.push({
                        x: channel,
                        'last_touch': tmp[channel].revenue_last_touch,
                        'data_driven': tmp[channel].revenue_data_driven
                    });
                }
            }

            return r;
        },

        chartDataTime() {
            const tmp = {};
            for (let i = 0; i < this.results.length; i++) {
                const r = this.results[i];
                const date = r.visit_start_date;
                if (! tmp[date]) {
                    tmp[date] = {
                        conversions_last_touch: 0,
                        revenue_last_touch: 0,
                        conversions_data_driven: 0,
                        revenue_data_driven: 0
                    }
                }

                if (this.channel === null || r.channel === this.channel) {
                    tmp[date].conversions_last_touch += r.conversions_last_touch;
                    tmp[date].revenue_last_touch += r.revenue_last_touch;
                    tmp[date].conversions_data_driven += r.conversions_data_driven;
                    tmp[date].revenue_data_driven += r.revenue_data_driven;
                }
            }

            const r = [];
            for (let visit_start_date in tmp) {
                if (this.type === 'conversions') {
                    r.push({
                        date: visit_start_date,
                        'last_touch': tmp[visit_start_date].conversions_last_touch,
                        'data_driven': tmp[visit_start_date].conversions_data_driven,
                    });
                } else if (this.type === 'revenue') {
                    r.push({
                        date: visit_start_date,
                        'last_touch': tmp[visit_start_date].revenue_last_touch,
                        'data_driven': tmp[visit_start_date].revenue_data_driven
                    });
                }
            }

            return r;
        }
    },
    methods: {
        async queryResults() {
            this.results = await postData('/api/analysis/results', {
                project: this.project.name,
                timebase: this.timebase,
                start_date: this.start_date,
                end_date: this.end_date
            });
        },
    }
}
</script>